@use 'sass:math';

.tout {
    position: relative;
    &--image {
        @media (max-width: 767px) {
            padding-bottom: 0 !important;
        }
    }
    &--card {
        padding: 0 math.div($grid-gap, 2);
        .tout__wrapper {
            max-width: 90rem;
            margin: 0 auto;
        }
        .tout__content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: $spacer-8 math.div($grid-gap, 2);
            @media (min-width: $max-width-container) {
                padding: $spacer-12 $spacer-20;
            }
            @include lg {
                align-items: flex-start;
            }
        }
        .tout__media {
            @include lg {
                &--offset {
                    transform: translateY($spacer-18);
                }
            }
        }
    }
    &--sm {
        display: flex;
        padding: $spacer-6;
        gap: $spacer-4;
        align-items: center;
        flex-direction: column;
        @include md {
            flex-direction: row;
            gap: $spacer-6;
        }
        .tout__media--tout5a {
            text-align: center;
            align-items: center;
            max-width: $spacer-22;
            width: 100%;
            height: 100%;
        }
        .tout__media {
            text-align: center;
            display: grid;
            align-items: center;
            max-width: $spacer-22;
        }
        .tout__icon {
            margin-bottom: 0;
        }
    }
    &--text-center {
        text-align: center;
    }
    &--center-horizontally {
        justify-content: center;
    }
    &--center-vertically {
        align-items: center;
    }
    &__content {
        position: relative;
        z-index: 1;
    }
    &__media-wrapper {
        position: relative;
        margin-top: $spacer-4;
        display: none;
        @include md {
            display: block;
            margin-top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            z-index: 0;
        }
    }
    &__media {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        video.internal {
            height: 100%;
            object-fit: cover;
        }
    }
    &__icon {
        margin-bottom: $spacer-4;
        font-size: $spacer-14;
    }
    &--roomy {
        @include md {
            display: flex;
            align-items: center;
            min-height: 35.5625rem;
        }
    }
}
