.jumplinks {
	position: relative;
	margin-bottom: 84px;
	@include md {
		margin-bottom: 0;
	}
	&__wrapper {
		z-index: 3;
		width: 100%;
		max-width: $max-width-container - $spacer-8;
		box-shadow: $elevation-close;
		background: $color-white;
		transition: max-width 0.4s;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		@include md {
			top: -52px;
		}
		&--active {
			max-width: 100vw;
			position: fixed;
			transform: translate(-50%, 0%);
			top: 0;
		}
	}
	&__container {
		display: flex;
		max-width: $max-width-container - $spacer-8;
		margin: 0 auto;
		overflow-x: auto;
		height: 5.25rem;
		&::-webkit-scrollbar {
			display: none;
		}
		@include md {
			height: 6.5rem;
			justify-content: center;
		}
	}
	&__link {
		padding: $spacer-4;
		color: $color-grey-80;
		text-decoration: none;
		flex-shrink: 0;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		* {
			line-height: $spacer-6;
		}
		@include md {
			padding: $spacer-7 $spacer-4;
			flex: 1;
		}
		&:hover {
			background: $color-grey-5;
		}
		&--active,
		&:focus-visible {
			color: $color-black;
			position: relative;
			background: $color-grey-5;
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: $spacer-1;
				background: $color-ruby;
			}
		}
		&:active,
		&:focus-within {
			outline: none;
			box-shadow: none;
		}
	}
}

.jumppoint:first-child > .section {
	&:first-child {
		@include md {
			padding-top: 8rem;
		}
	}
}

.jumppoint > .section {
	&:first-child {
		@include section-top;
	}
}

html:has(.content--jumplinks) {
	scroll-padding-top: 84px;
	@include md {
		scroll-padding-top: 104px;
	}
}
