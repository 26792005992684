.pagination {
   &__list {
      list-style: none;
      display: flex;
   }
   &__item {
      &:not(:last-child) {
         margin-right: $spacer-2;
      }
   }
   &__link {
      display: flex;
      line-height: normal;
      padding: $spacer-2 0.75rem;
      align-items: center;
      height: 100%;
      color: $color-grey-80;
      font-size: $font-size-small;
      border-radius: $border-radius-sm;
      text-decoration: none;
      transition: $transition-default;
      border: none;
      background: none;
      @include focus;
      &:hover {
         color: $color-grey-40;
         background: $color-grey-5;
      }
      &--active {
         background: $color-grey-5;
      }
      &--disabled {
         color: $color-grey-40;
         cursor: not-allowed;
         &:hover {
            background: none;
         }
      }
   }
   &__label {
      display: none;
      @include md {
         display: inline;
      }
   }
   &__prev {
      @include md {
         margin-right: $spacer-2;
      }
   }
   &__next {
      @include md {
         margin-left: $spacer-2;
      }
   }
}
.bg-grey-5 {
   .pagination__link {
      &:hover {
         background-color: $color-white;
      }
   }
   .pagination__link--active {
      background-color: $color-white;
   }
}
