.utilnav {
    display: none;
    @include desktop-navbar {
        background-color: var(--utility-nav-background-color);
        padding: $spacer-3 0;
        align-items: center;
        display: flex;
    }
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__items {
        display: flex;
        align-items: center;
        gap: $spacer-4;
    }
    &__logo {
        line-height: normal;
        display: flex;
    }
    &__links {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        @include desktop-navbar {
            flex-direction: row;
        }
    }
    &__link {
        @include nlc {
            @include desktop-navbar {
                margin-right: $spacer-4;
                margin-bottom: 0;
            }
        }
    }
}
