.font-en * {
    font-family: var(--font-merriweather-sans);
}

.font-jp * {
    font-family: var(--font-noto-sans-jp);
}

.font-kr * {
    font-family: var(--font-noto-sans-kr);
}

.font-th * {
    font-family: var(--font-noto-sans-looped-th);
}

body {
    @include body;
}

h1,
.h1 {
    @include h1;
    * {
        @include h1;
    }
}

h2,
.h2 {
    @include h2;
    * {
        @include h2;
    }
}

h3,
.h3 {
    @include h3;
    * {
        @include h3;
    }
}

h4,
.h4 {
    @include h4;
    * {
        @include h4;
    }
}

h5,
.h5 {
    @include h5;
    * {
        @include h5;
    }
}

p {
    @include body;
}

a {
    color: var(--btn-primary);
    cursor: pointer;
    @include focus;
    &:has(div) {
        display: block;
        text-decoration: none;
    }
}

.lead {
    @include lead;
    * {
        @include lead;
    }
    strong {
        font-weight: bold;
    }
    a {
        color: var(--btn-primary);
        cursor: pointer;
        @include focus;
        strong {
            color: var(--btn-primary);
        }
    }
}

.small {
    @include small;
}

.x-small {
    @include x-small;
}

.richtext {
    overflow-wrap: break-word;
    word-break: var(--word-break);
    ul,
    ol {
        padding-left: $spacer-4;
        &:not(:last-child) {
            margin-bottom: $spacer-6;
        }
        li {
            &:not(:last-child) {
                margin-bottom: $spacer-2;
            }
        }
    }
    h2,
    h3,
    h4,
    h5 {
        a {
            word-break: break-word;
        }
        &:last-child {
            margin-bottom: 0;
        } 
    }
    // p {
    //     line-height: var(--line-height-mobile-body);
    //     font-size: var(--font-size-mobile-body);

    //     @include md {
    //         line-height: var(--line-height-body);
    //         font-size: var(--font-size-body);
    //     }
    //     a {
    //         word-break: break-word;
    //     }
    //     &:last-child {
    //         margin-bottom: 0;
    //     }
    // }
    sup,
    sub {
        line-height: 0;
    }
}

sup {
    font-size: 0.75em !important;
}

.break {
    @include lg {
        display: block;
        margin-bottom: 0 !important;
    }
}
