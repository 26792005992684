.footnotes {
   p  {
      font-size: 0.75rem !important;
      line-height: 1.25rem !important;
      margin-bottom: 0;
      color: $color-grey-80;
   } 

   ol,
   ul {
      padding-left: $spacer-4;
      color: $color-grey-80;
      li {
         font-size: 0.75rem !important;
         line-height: 1.25rem !important;
         margin-bottom: 0;
         @include nlc {
            margin-bottom: $spacer-2;
         }
         &:first-child {
            margin-top: 1rem;
         }
      }
   }
}