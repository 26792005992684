.callout {
	padding: $spacer-12 $spacer-8;
	display: flex;
	align-items: center;
	&__title {
		margin-bottom: $spacer-2;
		* {
			color: $color-white;
		}
	}
	&__description {
		* {
			color: $color-white;
		}
		@include nlc {
			margin-bottom: $spacer-8;
		}
	}
	&__links {
		display: flex;
		flex-direction: column;
	}
	&__link {
		@include nlc {
			margin-bottom: $spacer-4;
		}
	}
}
