.tile {
	border-radius: $border-radius-lg;
	text-decoration: none;
	border: none;
	@include lift;
	@include focus;
	&--1 {
		display: flex;
		align-items: center;
		padding: $spacer-4 $spacer-4 $spacer-4 0;
		text-align: left;
		.tile__media {
			width: $spacer-10;
			height: $spacer-8;
			margin: 0 $spacer-5;
		}
		.tile__icon {
			font-size: $spacer-10;
			margin: 0 $spacer-5;
		}
		.tile__title * {
			@include h5;
		}
		.tile__description * {
			@include x-small;
		}
	}
	&--2 {
		padding: $spacer-12 $spacer-4 $spacer-4;
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;
		.tile__media {
			width: 5rem;
			height: 4rem;
			@include nlc {
				margin-bottom: $spacer-4;
			}
		}
		.tile__icon {
			font-size: $spacer-20;
			@include nlc {
				margin-bottom: $spacer-4;
			}
		}
		.tile__title {
			@include h4;
			* {
				@include h4;
			}
		}
		.tile__description * {
			@include small;
		}
	}
	&--3 {
		padding: $spacer-12 $spacer-4 $spacer-4;
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;
		.tile__media {
			width: 5rem;
			height: 4rem;
			@include nlc {
				margin-bottom: $spacer-2;
			}
		}
		.tile__icon {
			font-size: $spacer-16;
			@include nlc {
				margin-bottom: $spacer-2;
			}
		}
		.tile__title * {
			@include h5;
		}
		.tile__description * {
			@include x-small;
		}
	}
}
