@each $key, $val in $spacers {
    .last-child-mb-#{$key} {
        &:not(:last-child) {
            margin-bottom: #{$val};
        }
    }
    @include md {
        .last-child-mb-md-#{$key} {
            &:not(:last-child) {
                margin-bottom: #{$val};
            }
        }
    }
}

@each $key, $val in $spacers {
    .last-of-type-mb-#{$key} {
        &:not(:last-of-type) {
            margin-bottom: #{$val};
        }
    }
}

$general-utilities: (
    'box-shadow': (
        'prefix': 'elevation',
        'values': $elevations,
    ),
    'display': (
        'prefix': 'd',
        'values': (
            'none': none,
            'block': block,
            'flex': flex,
            'inline': inline-block,
        ),
    ),
    'align-items': (
        'prefix': 'align',
        'values': (
            'center': center,
            'start': flex-start,
        ),
    ),
    'justify-content': (
        'prefix': 'justify',
        'values': (
            'center': center,
        ),
    ),
    'overflow-x': (
        'prefix': 'overflow',
        'values': (
            'auto': auto,
        ),
    ),
    'text-align': (
        'prefix': 'text',
        'values': (
            'center': center,
            'right': right,
        ),
    ),
    'z-index': (
        'prefix': 'z',
        'values': (
            '1': 1,
            '2': 2,
        ),
    ),
    'height': (
        'prefix': 'h',
        'values': (
            '100': 100%,
        ),
    ),
    'object-fit': (
        'prefix': 'of',
        'values': (
            'cover': cover,
        ),
    ),
    'border-radius': (
        'prefix': 'br',
        'values': $border-radiuses,
    ),
    'flex-direction': (
        'prefix': 'flex',
        'values': (
            'column': column,
        ),
    ),
    'font-size': (
        'prefix': 'fs',
        'values': (
            'h1': $font-size-h2,
            'h2': $font-size-h2,
        ),
    ),
    'pointer-events': (
        'prefix': 'pe',
        'values': (
            'none': none,
        ),
    ),
);

@include responsive($general-utilities);
