.location {
   font-size: $font-size-small;
   color: $color-slate;
   @include md {
      font-size: $font-size-body;
   }
   &__icon {
      font-size: $font-size-h2;
      margin-bottom: $spacer-2;
   }
   &__office {
      margin-bottom: $spacer-1 !important;
      color: $color-ocean;
   }
   &__phone {
      line-height: $spacer-6;
   }
   &__address {
      margin-bottom: $spacer-4;
      * {
         line-height: $spacer-6;
      }
      span {
         display: block;
      }
   }
}
