.posts {
    scroll-margin-top: var(--scroll-margin-top-mobile);
    @include md {
        scroll-margin-top: var(--scroll-margin-top);
    }
    &__search {
        display: flex;
        align-items: flex-end;
        gap: $spacer-4;
        margin-bottom: $spacer-4;
        flex-direction: column;
        border-bottom: 1px solid $color-grey-20;
        padding-bottom: $spacer-4;
        @include md {
            padding-bottom: 0;
            border-bottom: none;
            gap: $spacer-7;
            flex-direction: row;
        }
    }
    &__searchbar {
        width: 100%;
    }
    &__filters {
        display: flex;
        gap: $spacer-4;
        margin-bottom: $spacer-8;
        flex-direction: column;
        width: 100%;
        @include lg {
            gap: $spacer-7;
            align-items: flex-end;
            flex-direction: row;
        }
    }
    &__filter {
        @include lg {
            width: 300px;
        }
    }
    &__sort {
        margin-left: auto;
        .dropdown__trigger {
            border: none;
            justify-content: flex-end;
            gap: $spacer-3;
        }
        .dropdown__menu {
            width: 150px;
            right: 0;
        }
    }
    &__clear {
        @include resetButton;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        color: $color-grey-40;
        right: $spacer-10;
        transition: $transition-default;
        &:hover {
            color: $color-grey-80;
        }
    }
    &__controls {
        display: flex;
        justify-content: space-between;
        flex: 1;
    }
    &__listing {
        display: grid;
        row-gap: $spacer-6;
        @include md {
            row-gap: $spacer-12;
        }
        @include nlc {
            margin-bottom: $spacer-14;
        }
        &--2a {
            row-gap: 0;
            .post {
                border-bottom: 1px solid $color-grey-20;
                padding: $spacer-6 0;
            }

            .post__title {
                width: 304px;
            }
            .post__content {
                @include nlc {
                    margin-bottom: $spacer-4;
                }
                @include md {
                    display: flex;
                    gap: $spacer-4;
                }
            }
            .post__content-wrapper {
                flex: 1;
            }
            .post__links {
                flex-direction: column;
                flex-shrink: 0;
            }
        }
        &--3a {
            gap: $spacer-7;
            grid-template-columns: repeat(1, 1fr);
            @include md {
                grid-template-columns: repeat(2, 1fr);
            }
            @include lg {
                grid-template-columns: repeat(3, 1fr);
            }
            .post {
                display: flex;
                flex-direction: column;
                gap: 0;
                &--link {
                    transition: $transition-default;
                    &:hover {
                        @include elevation-closest;
                        .post__content {
                            background-color: $color-white;
                        }
                        .post__tag {
                            background-color: $color-grey-5;
                        }
                    }
                }
            }
            .post__media {
                width: 100%;
                height: 240px;
                position: relative;
            }
            .post__content {
                padding: $spacer-6;
                background: $color-grey-5;
                flex: 1;
                display: flex;
                flex-direction: column;
                transition: $transition-default;
            }
            .post__tag {
                background: $color-white;
            }
            .post__links {
                display: flex;
                gap: $spacer-4;
                @include md {
                    align-items: center;
                }
            }
            .post__link {
                margin-bottom: 0px !important;
            }
        }
    }
}

.post {
    display: flex;
    gap: $spacer-6;
    flex-direction: column;
    @include md {
        flex-direction: row;
    }
    &__media {
        @include md {
            width: 240px;
            height: 180px;
            flex-shrink: 0;
        }
    }
    &__content {
        flex: 1;
    }
    &__date {
        font-size: $font-size-small;
        color: $color-grey-60;
    }
    &__title {
        @include nlc {
            margin-bottom: $spacer-4;
        }
    }
    &__title * {
        color: $color-black;
    }
    &__description {
        @include nlc {
            margin-bottom: $spacer-4;
        }
        table {
            table-layout: fixed;
        }
    }

    &__tags {
        display: flex;
        gap: $spacer-2;
        flex-wrap: wrap;
        @include nlc {
            margin-bottom: $spacer-4;
        }
    }
    &__tag {
        font-size: $font-size-x-small;
        color: $color-grey-60;
        padding: $spacer-1 $spacer-2;
        border-radius: $border-radius-sm;
        background: $color-grey-5;
        line-height: 1.25rem;
    }

    &__links {
        display: flex;
        justify-content: flex-start;
        gap: $spacer-2;
        flex-direction: column;
        @include md {
            flex-direction: row;
        }
    }
    &__clear {
        @include resetButton;
        color: $color-crimson;
        text-decoration: underline;
        height: 48px;
        &:hover {
            text-decoration: none;
        }
    }
    &__submit {
        height: 48px;
    }
}
