.introduction {
    &:not(:last-child) {
        margin-bottom: $spacer-8;
        @include md {
            margin-bottom: $spacer-14;
        }
    }
    &__title {
        &:not(:last-child) {
            margin-bottom: $spacer-6;
        }
        h2:last-child {
            margin-bottom: 0;
        }
        &--crimson * {
            color: $color-crimson;
        }
        &--crimson-line {
            border-left: $spacer-1 var(--introduction-accent-color) solid;
            padding: $spacer-6 0 $spacer-6 $spacer-8;
        }
        &--cyan * {
            color: $color-cyan;
        }
    }
    &--sm {
        &:not(:last-child) {
            margin-bottom: $spacer-8;
        }
    }
    &--xs {
        &:not(:last-child) {
            margin-bottom: $spacer-6;
        }
    }
    &--accent {
        .eyebrow {
            color: var(--introduction-accent-color);
        }
        border-left: $spacer-1 solid var(--introduction-accent-color);
        padding: $spacer-6 0 $spacer-6 $spacer-8;
        
    }
    &--fancy {
        .introduction__title h2 {
            display: inline-block;
            border-top: 1px solid $color-white;
            border-bottom: 1px solid $color-white;
            padding: 1rem 0;
            font-size: 2.375rem;
            line-height: 3.125rem;
        }
    }
}
