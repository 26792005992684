.wrapper {
    max-width: $wrapper;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.container {
    @include container;
}

.section {
    @include section;
    &--top-tight {
        padding-top: $spacer-12;
    }
    &--bottom-tight {
        padding-bottom: $spacer-12;
    }
    &--top-none {
        padding-top: 0;
    }
    &--bottom-none {
        padding-bottom: 0;
    }
    &--tight {
        padding-top: $spacer-12;
        padding-bottom: $spacer-12;
    }
    &--top-roomy {
        padding-top: $spacer-12;
        @include md {
            padding-top: $spacer-32;
        }
    }
    &--bottom-roomy {
        padding-bottom: $spacer-12;
        @include md {
            padding-bottom: $spacer-32;
        }
    }
    &--roomy {
        padding-top: $spacer-12;
        padding-bottom: $spacer-12;
        @include md {
            padding-top: $spacer-32;
            padding-bottom: $spacer-32;
        }
    }
    &--sm {
        .container {
            max-width: $breakpoint-md;
        }
    }
}

.content {
    &--jumplinks > .section {
        &:first-child {
            @include md {
                padding-top: 8rem;
            }
        }
    }
}

[class*='grid'] {
    gap: $grid-gap;
    display: grid;
}

.v-center {
    display: grid;
    align-content: center;
}

$grid-utilities: (
    'grid-template-columns': (
        'prefix': 'grid',
        'values': (
            '1': repeat(1, 1fr),
            '2': repeat(2, 1fr),
            '3': repeat(3, 1fr),
            '4': repeat(4, 1fr),
            '5': repeat(5, 1fr),
            '6': repeat(6, 1fr),
            '7': repeat(7, 1fr),
            '8': repeat(8, 1fr),
            '9': repeat(9, 1fr),
            '10': repeat(10, 1fr),
            '11': repeat(11, 1fr),
            '12': repeat(12, 1fr),
        ),
    ),
    'grid-column': (
        'prefix': 'col',
        'values': (
            '1': span 1,
            '2': span 2,
            '3': span 3,
            '4': span 4,
            '5': span 5,
            '6': span 6,
            '7': span 7,
            '8': span 8,
            '9': span 9,
            '10': span 10,
            '11': span 11,
            '12': span 12,
        ),
    ),
    'grid-column-start': (
        'prefix': 'col-start',
        'values': (
            '1': 1,
            '2': 2,
            '3': 3,
            '4': 4,
            '5': 5,
            '6': 6,
            '7': 7,
            '8': 8,
            '9': 9,
            '10': 10,
            '11': 11,
            '12': 12,
            '13': 13,
        ),
    ),
    'grid-column-end': (
        'prefix': 'col-end',
        'values': (
            '1': 1,
            '2': 2,
            '3': 3,
            '4': 4,
            '5': 5,
            '6': 6,
            '7': 7,
            '8': 8,
            '9': 9,
            '10': 10,
            '11': 11,
            '12': 12,
            '13': 13,
        ),
    ),
    'grid-row-start': (
        'prefix': 'row-start',
        'values': (
            '1': 1,
        ),
    ),
    'gap': (
        'prefix': 'gap',
        'values': $spacers,
    ),
    'column-gap': (
        'prefix': 'col-gap',
        'values': $spacers,
    ),
    'row-gap': (
        'prefix': 'row-gap',
        'values': $spacers,
    ),
    'order': (
        'prefix': 'order',
        'values': (
            '1': 1,
            '2': 2,
        ),
    ),
);

@include responsive($grid-utilities);
