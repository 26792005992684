.video {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	width: 100%;
	overflow: hidden;
	&__frame {
		position: absolute;
		top: 0 !important;
		left: 0;
		width: 100% !important;
		height: 100% !important;
		object-fit: fill;
		z-index: 2;
	}
	&__image {
		@include aspect-ratio(16, 9);
		object-fit: cover;
		max-width: 100%;
		height: auto;
		width: 100%;
		position: relative;
		height: 100%;
		&--natural {
			aspect-ratio: unset;
		}
	}
	&__skeleton {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.react-loading-skeleton {
		line-height: 0 !important;
		border-radius: 0 !important;
	}
}
