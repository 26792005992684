@use 'sass:math';

/* === Typography === */

$font-family-base: var(--font-merriweather-sans);

$font-size-base: 1rem; //16px

$font-size-h1: $font-size-base * 3; // 48px
$font-size-h2: $font-size-base * 2.25; // 36px
$font-size-h3: $font-size-base * 1.5; // 24px
$font-size-h4: $font-size-base * 1.25; // 20px
$font-size-h5: $font-size-base; // 16px
$font-size-lead: $font-size-base * 1.375; // 22px
$font-size-body: $font-size-base; // 16px
$font-size-small: $font-size-base * 0.875; // 14px
$font-size-x-small: $font-size-base * 0.75; // 12px
$font-size-h1-404: $font-size-base * 4; // 64px

$font-size-mobile-h1: $font-size-base * 2.5; // 40px
$font-size-mobile-h2: $font-size-base * 2; // 32px
$font-size-mobile-h3: $font-size-base * 1.5; // 24px
$font-size-mobile-h4: $font-size-base * 1.25; // 20px
$font-size-mobile-h5: $font-size-base; // 16px
$font-size-mobile-lead: $font-size-base * 1.25; // 20px
$font-size-mobile-body: $font-size-base; // 16px
$font-size-mobile-small: $font-size-base * 0.75; // 12px
$font-size-mobile-h1-404: $font-size-base * 2; // 64px

$line-height-h1: $font-size-base * 4; // 64px
$line-height-h2: $font-size-base * 3.375; // 54px
$line-height-h3: $font-size-base * 2.25; // 40px
$line-height-h4: $font-size-base * 1.875; // 30px
$line-height-h5: $font-size-base * 1.75; // 28px
$line-height-lead: $font-size-base * 2.5; // 40px
$line-height-body: $font-size-base * 1.75; // 28px
$line-height-small: $font-size-base * 1.25; // 20px
$line-height-x-small: $font-size-base * 1.25; // 20px
$line-height-h1-404: $font-size-base * 5; // 80px

$line-height-mobile-h1: $font-size-base * 3.5; // 56px
$line-height-mobile-h2: $font-size-base * 3; // 48px
$line-height-mobile-h3: $font-size-base * 2.25; // 40px
$line-height-mobile-h4: $font-size-base * 1.875; // 30px
$line-height-mobile-h5: $font-size-base * 1.75; // 28px
$line-height-mobile-lead: $font-size-base * 2.25; // 36px
$line-height-mobile-body: $font-size-base * 1.75; // 28px
$line-height-mobile-small: $font-size-base * 1.125; // 18px
$line-height-mobile-h1-404: $font-size-base * 3; // 80px

$font-weight-regular: normal; // 400
$font-weight-bold: bold; // 700

/* === Spacing === */

$spacer: 0.25rem; // 4px

$spacer-1: $spacer; // 4px
$spacer-2: $spacer * 2; // 8px
$spacer-3: $spacer * 3; // 12px
$spacer-4: $spacer * 4; // 16px
$spacer-5: $spacer * 5; // 20px
$spacer-6: $spacer * 6; // 24px
$spacer-7: $spacer * 7; // 28px
$spacer-8: $spacer * 8; // 32px
$spacer-9: $spacer * 9; // 36px
$spacer-10: $spacer * 10; // 40px
$spacer-12: $spacer * 12; // 48px
$spacer-14: $spacer * 14; // 56px
$spacer-16: $spacer * 16; // 64px
$spacer-18: $spacer * 18; // 72px
$spacer-20: $spacer * 20; // 80px
$spacer-22: $spacer * 22; // 88px
$spacer-24: $spacer * 24; // 96px
$spacer-32: $spacer * 32; // 128px

$spacers: (
    '0': 0,
    '1': $spacer-1,
    '2': $spacer-2,
    '3': $spacer-3,
    '4': $spacer-4,
    '5': $spacer-5,
    '6': $spacer-6,
    '7': $spacer-7,
    '8': $spacer-8,
    '9': $spacer-9,
    '10': $spacer-10,
    '12': $spacer-12,
    '14': $spacer-14,
    '16': $spacer-16,
    '18': $spacer-18,
    '20': $spacer-20,
    '22': $spacer-22,
    '24': $spacer-24,
);

/* === Primary Colors === */

$color-crimson: #c8102e;
$color-titanium: #898d8d;

/* === Neutral Colors === */

$color-sand: #a39382;
$color-oatmeal: #b7b09c;
$color-slate: #505759;

/* === Accent Colors === */

$color-orange: #cf4520;
$color-maroon: #8a2a2b;
$color-ruby: #9b2743;
$color-purple: #624c80;
$color-blue: #356584;
$color-royal: #00568c;
$color-ocean: #00626c;
$color-forest: #446a5f;
$color-brown: #645142;
$color-copper: #8e6325;
$color-cyan: #3a7e72;

/* === Neutral Colors === */

$color-honey: #d7a181;
$color-beige: #d3bba8;
$color-rose: #c09aa1;
$color-lilac: #af95a6;
$color-denim: #7a99ac;
$color-sky: #a4bcd0;
$color-sky-25: mix(white, $color-sky, 75%);
$color-sky-35: mix(white, $color-sky, 65%);
$color-steel: #a4bcc2;
$color-sage: #94a596;
$color-khaki: #a89968;
$color-tan: #ceb888;

/* === Utility Colors === */

$color-white: #ffffff;
$color-black: #000000;
$color-grey-80: #333333;
$color-grey-60: #666666;
$color-grey-40: #999999;
$color-grey-20: #cccccc;
$color-grey-10: mix(white, $color-black, 90%);
$color-grey-5: #f2f2f2;
$color-focus: #0099ff;
$color-focus-hover: #0066ff;
$color-error: #ff0000;
$color-warning: #ffb600;

/* === Trial Colors === */

$color-encircle: #511c6c;
$color-progress: #0b5670;
$color-early-tavr: #0f3f58;
$color-hpi-care: #c4d3e7;
$color-clasp-ii-tr: #333f48;

/* === Social Colors === */

$color-facebook: #4267b2;
$color-instagram: #c13584;
$color-twitter: #1da1f2;
$color-linkedin: #0075b5;
$color-youtube: #ff0000;

$colors: (
    'crimson': $color-crimson,
    'titanium': $color-titanium,
    'sand': $color-sand,
    'oatmeal': $color-oatmeal,
    'slate': $color-slate,
    'orange': $color-orange,
    'maroon': $color-maroon,
    'ruby': $color-ruby,
    'purple': $color-purple,
    'blue': $color-blue,
    'royal': $color-royal,
    'ocean': $color-ocean,
    'forest': $color-forest,
    'brown': $color-brown,
    'copper': $color-copper,
    'honey': $color-honey,
    'beige': $color-beige,
    'rose': $color-rose,
    'lilac': $color-lilac,
    'denim': $color-denim,
    'sky': $color-sky,
    'steel': $color-steel,
    'sage': $color-sage,
    'khaki': $color-khaki,
    'tan': $color-tan,
    'grey-5': $color-grey-5,
    'white': $color-white,
    'cyan': $color-cyan,
);

$neutrals: (
    'titanium': $color-titanium,
    'sand': $color-sand,
    'oatmeal': $color-oatmeal,
    'slate': $color-slate,
);

$accents: (
    'orange': $color-orange,
    'maroon': $color-maroon,
    'ruby': $color-ruby,
    'purple': $color-purple,
    'blue': $color-blue,
    'royal': $color-royal,
    'ocean': $color-ocean,
    'forest': $color-forest,
    'brown': $color-brown,
    'copper': $color-copper,
);

$backgrounds: (
    'white': $color-white,
    'grey-5': $color-grey-5,
    'sky-light': #eef3f6,
    'sky-lightest': #f5f8fa,
    'steel-light': #edf2f3,
    'steel-lightest': #f6f8f9,
    'sage-light': #e9ece9,
    'sage-lightest': #f4f6f4,
    'beige-light': #f6f2ee,
    'beige-lightest': #faf7f5,
    'denim': #d3dde4,
    // denim doesn't have light bc that value already existed in CMS before color updates
    'denim-lightest': #f2f5f7,
    'honey-light': #f8eee8,
    'honey-lightest': #fbf6f3,
    'lilac-light': #f3eced,
    'lilac-lightest': #f9f6f6,
    'mint-light': #e3f2f1,
    'mint-lightest': #f1f9f8,
    'tan-light': #f5f0e6,
    'tan-lightest': #fbf9f4,
    'ruby-light': #fbeff2,
    'ruby-lightest': #fdf7f8,
    'slate': $color-slate,
    'crimson': $color-crimson,
    'encircle': $color-encircle,
    'progress': $color-progress,
    'early-tavr': $color-early-tavr,
    'hpi-care': $color-hpi-care,
    'clasp-ii-tr': $color-clasp-ii-tr,
    'sky-35': $color-sky-35,
);

$social: (
    'facebook': $color-facebook,
    'instagram': $color-instagram,
    'twitter': $color-twitter,
    'linkedin': $color-linkedin,
    'youtube': $color-youtube,
);

$gradients: (
    'crimson-gradient': $color-crimson,
);

/* === Elevation === */

$elevation-closest: 0px 4px 8px rgba($color-black, 0.16);
$elevation-close: 0px 8px 16px rgba($color-black, 0.16);
$elevation-far: 0px 16px 24px rgba($color-black, 0.16);
$elevation-farthest: 0px 24px 32px rgba($color-black, 0.16);

$elevations: (
    'closest': $elevation-closest,
    'close': $elevation-close,
    'far': $elevation-far,
    'farthest': $elevation-farthest,
);

/* === Layout === */

$wrapper: 120rem;
$max-width-container: 71.25rem;
$grid-gap: 1.75rem;
$navbar-desktop-height: 5.3125rem;
$navbar-mobile-height: 3.75rem;

/* === Breakpoints === */

$breakpoint-sm: 0px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;

$breakpoints: (
    'sm': $breakpoint-sm,
    'md': $breakpoint-md,
    'lg': $breakpoint-lg,
);

/* === Border Radius === */

$border-radius-sm: $spacer-1; // 4px
$border-radius-lg: $spacer-2; // 8px
$border-radius-xl: $spacer-1; // 4px

$border-radiuses: (
    '0': 0,
    'sm': $border-radius-sm,
    'lg': $border-radius-lg,
    'xl': $border-radius-xl,
);

/* === Buttons === */

$btn-border-radius: $border-radius-sm;
$btn-padding: 0.625rem 1.5rem; // 10px 24px
$btn-padding-xl: $spacer-3 $spacer-7; // 12px 28px
$btn-padding-lg: $spacer-3 $spacer-7; // 12px 28px
$btn-padding-sm: $spacer-2 $spacer-4; // 8px 16px

/* === Forms === */

$input-padding: 0.625rem $spacer-4;
$input-padding-sm: 0.4375rem $spacer-4;
$input-padding-lg: 0.875rem $spacer-4;
$input-border-radius: $btn-border-radius;

$input-height-lg: $spacer-12;
$input-height-md: $spacer-10;
$input-height-sm: $spacer-8;

/* === Tables === */

$table-zebra-color: #efefef;
$table-border-color: #dddddd;
$table-heading-color: #e4e4e4;

/* === Transitions === */

$transition-default: 0.2s all;
$transition-background: 0.2s background-color;
