.notification {
	background: mix(white, $color-sky, 60%);
	padding: $spacer-4 0;
	max-width: $wrapper;
	margin: 0 auto;
	&__container {
		@include container;
		display: flex;
		justify-content: space-between;
		position: relative;
	}
	&__content {
		margin-right: $spacer-8;
		* {
			font-size: $font-size-x-small;
			line-height: $line-height-x-small;
			color: $color-grey-80;
			@include md {
				font-size: $font-size-small;
				line-height: $line-height-small;
			}
		}
	}
	&__close {
		line-height: 0;
		background: none;
		border: none;
		position: absolute;
		padding: $spacer-4;
		top: -$spacer-3;
		right: 0;
		@include focus;
	}
}
