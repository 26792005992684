.statistic {
  padding: $spacer-6;
  border-top: $spacer-1 solid $color-titanium;
  @include gradient;
  &__head {
    display: flex;
    align-items: center;
    margin-bottom: $spacer-4;
  }
  &__stat {
    margin-right: $spacer-4;
    color: $color-ruby;
    font-weight: $font-weight-bold;
    font-size: 3rem;
    line-height: normal;
    @include md {
      font-size: 3.5rem;
    }
  }
  &__title {
    * {
      color: $color-ruby;
      font-weight: $font-weight-regular;
    }
    h3 {
      font-size: $font-size-mobile-lead;
      word-break: break-word;

      @include md {
        font-size: 1.375rem;
      }
    }
  }
  &__icon {
    font-size: 5rem;
  }
  &--1 {
    border-top: none;
    background: none;
    display: flex;
    padding: $spacer-2;
    &:not(:last-child) {
      margin-bottom: $spacer-2;
    }
    @include md {
      padding: $spacer-6;
    }
    .statistic__title {
      h3 {
        margin-left: 0;
        margin-bottom: $spacer-2;
      }

      &--small {
        h3 {
          font-size: $font-size-body;
          font-weight: $font-weight-bold;
          line-height: $line-height-h5;
        }
      }
    }
    .statistic__content {
      border-left: 2px solid $color-ruby;
      padding-left: $spacer-6;
      flex-basis: 70%;
    }
    .statistic__stat {
      font-size: $spacer-8;
      margin-right:auto;
      flex-basis: 30%;
      @include md {
        font-size: $spacer-12;
      }
      &--small {
        font-size: $spacer-8;
      }
    }
  }
  &--2 {
    background: $color-white;
    border-top: none;
    padding: $spacer-2;
    @include md {
      padding: $spacer-6;
    }
    .statistic__stat {
      font-size: $spacer-12;
      &--small {
        font-size: $spacer-10;
      }
    }
    .statistic__title h3 {
      margin-left: 0;
      &:not(:last-child) {
        margin-bottom: $spacer-4;
      }
    }
  }
  &--3 {
    border-top: none;
    background: none;
    padding: 0;
    .statistic__icon {
      font-size: 3rem;
    }
    .statistic__head {
      display: flex;
    }
    .statistic__title h3 {
      margin-left: 0;
      margin-bottom: $spacer-2;
    }
    .statistic__content {
      border-left: 2px solid $color-ruby;
      margin-left: $spacer-6;
      padding-left: $spacer-6;
    }
    .statistic__stat {
      font-size: $font-size-h2;
    }
  }
}
