.caption {
	@include small;
	text-align: center;
	margin-bottom: 0;
	margin-top: $spacer-4;
	&--left {
		text-align: left;
	}
	&--lg {
		font-size: $font-size-h4;
		line-height: $line-height-h4;
		font-weight: $font-weight-bold;
	}
	* {
		@include small;
	}
}
