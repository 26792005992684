.compliance {
   @include md {
      display: flex;
      align-items: center;
   }
   &__image {
      margin-bottom: $spacer-4;
      display: flex;
      flex-shrink: 0;
      @include md {
         margin-bottom: 0;
         margin-right: $spacer-6;
      }
   }
   &__description p {
      @include small;
   }
}
