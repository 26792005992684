.articles {
   &__filters {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: left;
      column-gap: $spacer-7;
      margin-bottom: $spacer-8;

      @include md {
         margin-bottom: $spacer-14;
         row-gap: $spacer-7;
      }
   }

   &__filter {
      width: 100%;
      margin-bottom: $spacer-2;

      @include md {
         width: 18.75rem;
         margin-bottom: 0;
      }
   }

   &__filter-tag {
      display: grid;

      .tile {
         border: 1px solid $color-grey-20;
         background-color: transparent;
      }

      &.active {
         .tile {
            border: 1px solid $color-slate;
            background-color: $color-grey-5;
         }
      }

      &:hover {
         .tile {
            background-color: $color-grey-5;
         }
      }

      .tile__icon {
         @include md {
            height: 10rem;
            max-width: unset;
         }
      }
   }

   &__dropdown {
      min-width: 100%;
   }

   &--card {
      .articles__display {
         display: grid;
         gap: 1.75rem;
         grid-template-columns: repeat(1, 1fr);

         @include md {
            grid-template-columns: repeat(2, 1fr);
         }

         @include lg {
            grid-template-columns: repeat(3, 1fr);
         }
      }
   }

   &--three-column {
      .articles__filters {
         .articles__filter {
            &:last-child {
               margin-bottom: 0px;
            }
         }
      }
   }
}

.article {
   &:not(:last-child) {
      margin-bottom: $spacer-12;
   }

   &__date {
      color: $color-grey-60;
      font-size: $font-size-small;
      line-height: $line-height-small;
   }

   &__title {
      font-size: 24px;
      line-height: 2.5rem;
      font-weight: 700;
      &:not(:last-child) {
         margin-bottom: $spacer-4;
      }
   }

   &__thumbnail {
      width: 100%;
      max-width: 100%;
      height: 11.25rem;
      position: relative;
   }

   &__description {
      margin-bottom: $spacer-4;
      color: $color-grey-80;
   }

   &__tags {
      margin-bottom: $spacer-4;
      display: flex;
      flex-wrap: wrap;
      gap: $spacer-2 0;
   }

   &__tag {
      color: $color-grey-60;
      background-color: $color-grey-5;
      font-size: $font-size-x-small;
      line-height: $line-height-x-small;
      padding: $spacer-1 $spacer-2;
      border-radius: $spacer-1;

      &:not(:last-child) {
         margin-right: $spacer-2;
      }
   }

   &__links {
      display: flex;
      gap: $spacer-2;
      flex-direction: column;

      @include md {
         flex-direction: row;
         ;
      }
   }

   &__link {
      text-decoration: underline;

      svg {
         margin-left: $spacer-2;
      }
   }

   &--list-thumbnail {
      display: grid;
      gap: $spacer-6;

      @include md {
         grid-template-columns: 15rem 1fr;
      }
   }

   &--card {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
         margin-bottom: 0;
      }

      .article__thumbnail {
         height: 15rem;
         object-fit: cover;
      }

      .article__content {
         padding: $spacer-6;
         background: $color-grey-5;
         flex-grow: 1;
      }

      .article__tag {
         background: $color-white;
      }
   }

   &--thumbnail-buttons {
      display: grid;
      gap: $spacer-6;

      @include md {
         grid-template-columns: 15rem 1fr;
      }

      .article__date {
         display: none;
      }

      .article__title {
         line-height: $spacer-6;
      }

      .article__tags {
         display: none;
      }

      .article__links {
         align-items: center;
      }
   }

   &--three-column {
      display: grid;
      gap: $spacer-6;
      margin-bottom: 0px !important;

      @include md {
         .article__content {
            display: flex;
            flex-direction: row !important;
            gap: $spacer-6;
            padding: $spacer-6 0 $spacer-6 0;
         }
      }

      .article__date {
         display: none;
      }

      .article__title {
         flex-basis: 30%;
      }

      .article__description {
         align-items: right;
         flex-basis: 45%;
      }
      .article__tags {
         display: none;
      }
      .article__links {
         flex-direction: column;
         align-items: right;
         flex-basis: 20%;
      }

      .article__content {
         display: flex;
         flex-direction: column;
         gap: $spacer-4;
         padding: $spacer-6 0 $spacer-6 0;

         .article__title {
            font-weight: $font-weight-bold;
            font-size: 20px;
            line-height: 1.875rem;
         }
      }

      border-bottom: 1px solid $color-grey-20;
   }
}

.bg-grey-5 {
   .article__tag {
      background-color: $color-white;
   }

   .articles--card {
      .article__content {
         background-color: $color-white;
      }

      .article__tag {
         background-color: $color-grey-5;
      }
   }
}