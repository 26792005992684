.fas {
    &__main {
        margin-left: -0.875rem;
        margin-right: -0.875rem;
        @include md {
            margin: auto;
            @include elevation-closest;
        }
    }
    &__hospitals {
        height: 38.75rem;
        @include md{
            max-height: 31.0625rem;
        }
        @include scrollbar;
    }
    &__hospital {
        padding: $spacer-4;
        transition: $transition-default;
        min-height: 10.375rem;
        position: relative;
        display: block;
        background: none;
        border: none;
        text-align: left;
        width: 100%;
        @include nlc {
            border-bottom: 1px solid $color-grey-20;
        }
        &:hover {
            background: mix($color-white, $color-grey-5, 60%);
        }
        &:focus {
            outline: none;
            background: mix($color-white, $color-grey-5, 20%);
        }
    }
    &__hospital-title {
        color: $color-grey-80;
        max-width: 16.875rem;
    }
    &__hospital-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: $spacer-4;
        color: $color-grey-40;
    }
    &__list-heading {
        background: $color-grey-5;
        color: $color-white;
        padding: 0.625rem $spacer-4;
    }
    &__address {
        margin-bottom: $spacer-4;
        font-size: $font-size-small;
        line-height: normal;
        color: $color-grey-60;
    }
    &__contact-details {
        display: flex;
        align-items: center;
    }

    &__distance {
        color: $color-grey-60;
        margin-right: $spacer-4;
        width: $spacer-18;
        font-size: $font-size-x-small;
    }
    &__map {
        height: 25rem;
        @include md {
            height: 38.75rem;
        }
    }
    &__active {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 38.75rem;
    }
    &__head {
        background: $color-sky-25;
        padding: $spacer-4;
    }
    &__back {
        color: $color-crimson;
        margin-bottom: $spacer-4;
        @include focus;
        background: none;
        border: none;
        text-align: left;
        width: 100%;
        display: inline-block;
        width: auto;
    }
    &__hospital-thumbnail {
        position: relative;
        height: 10.3125rem;
        margin-bottom: $spacer-2;
    }
    &__hospital-name {
        color: $color-black;
        margin-bottom: $spacer-2;
    }
    &__hospital-secondary-name {
        @include x-small;
        color: $color-grey-60;
    }
    &__body-contact {
        padding: $spacer-4;
        border-bottom: 1px solid $color-grey-20;
    }
    &__body-address {
        font-size: $font-size-x-small;
        color: $color-grey-60;
        margin-bottom: $spacer-4;
    }
    &__results {
        position: relative;
    }
    &__surgeons {
        padding: $spacer-4;
        @include scrollbar;   
    }
    &__surgeon {
        @include nlc {
            margin-bottom: $spacer-3;
        }
    }
    &__surgeon-fullname {
        font-size: $font-size-small;
        color: $color-black;
        line-height: normal;
    }
    &__surgeon-role {
        color: $color-grey-60;
        font-size: $font-size-x-small;
    }
    &__footer {
        margin-top: auto;
        padding: $spacer-4;
        background: $color-grey-5;
    }
}
