.skipper {
	padding: $spacer-4 0;
	display: block;
	position: absolute;
	top: -1000px;
	text-align: center;
	width: 100%;
	max-width: $wrapper;
	z-index: 10;
	background: $color-grey-80;
	color: $color-white;
	&:focus {
		position: fixed;
		top: 0;
	}
}
