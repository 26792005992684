.mosaic {
   display: grid;
   grid-template-rows: repeat(2, 5.013rem); // 80.21px = 5.013rem
   grid-template-columns: 1fr 7.564rem; // 121.02px = 7.564rem
   margin-bottom: $spacer-8;
   @include md {
      grid-template-rows: repeat(2, 7.125rem); // 114px = 7.125rem
      grid-template-columns: 1fr 10.75rem 1fr; // 172px = 10.75rem
      margin-bottom: $spacer-12;
   }
   gap: $spacer-1 + $spacer-2;
   &__item {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      &--0 {
         grid-row: 1 / 3;
      }
      &--1 {
         grid-row: 1 / 2;
      }
      &--2 {
         grid-row: 2 / 3;
      }
      &--3 {
         display: none;
         @include md {
            display: grid;
            grid-row: 1 / 3;
         }
      }
   }
}
