$text-colors: (
    'crimson': $color-crimson,
    'black': $color-black,
    'steel': $color-steel,
    'sky': $color-sky,
    'sage': $color-sage,
    'slate': $color-slate,
    'titanium': $color-titanium,
    'denim': $color-denim,
    'white': $color-white,
    'grey-80': $color-grey-80,
    'orange': $color-orange,
    'maroon': $color-maroon,
    'ruby': $color-ruby,
    'purple': $color-purple,
    'copper': $color-copper,
    'royal': $color-royal,
    'ocean': $color-ocean,
    'cyan': $color-cyan,
);

@each $key, $val in $text-colors {
    .text-#{$key} {
        color: $val;
        * {
            color: $val;
        }
    }
}
