.search {
  &__container {
    margin-top: 2rem !important;
  }

  &__results {
    margin-top: $spacer-12;
    line-height: 1.25rem;
    color: $color-grey-60;
  }

  &__no_results {
    margin-bottom: 0 !important;
  }

  &__results-display {
    margin-top: $spacer-2;
  }

  &__result {
    &:not(:last-child) {
      margin-bottom: $spacer-8;
    }
  }

  &__title {
    margin-bottom: 0 !important;
    color: $color-crimson;
  }

  &__links {
    display: flex;
    gap: $spacer-2;
    flex-direction: column;

    @include md {
      flex-direction: row;
    }
  }
}

.result {
  &:not(:last-child) {
    margin-bottom: $spacer-8;
  }

  &__title {
    margin-bottom: 0 !important;
  }

  &__links {
    display: flex;
    gap: $spacer-2;
    flex-direction: column;
    font-size: $font-size-small;
    line-height: $line-height-small;

    a {
      text-decoration: underline;
      word-wrap: break-word;
    }

    @include md {
      flex-direction: row;
    }
  }
}