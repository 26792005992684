$scale-max-width: 30rem;

.survey {
    @include lg {
        box-shadow: $elevation-closest;
    }
    &__head {
        background: $color-grey-5;
        display: none;
        align-items: center;
        justify-content: flex-end;
        padding: $spacer-1 $spacer-6;
        @include lg {
            display: flex;
        }
    }
    &__heading {
        font-weight: $font-weight-bold;
        color: $color-black;
        text-align: center;
        width: 7.25rem;
    }
    &__item {
        @include lg {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: $spacer-1 $spacer-6;
            min-height: 3.875rem;
            border-top: 1px solid $color-grey-40;
            background: $color-white;
        }
    }
    &__label {
        color: $color-black;
        margin-bottom: $spacer-4;
        font-weight: $font-weight-bold;
        @include lg {
            margin-bottom: 0;
            font-weight: $font-weight-regular;
            width: 16rem;
        }
    }
    &__options {
        display: flex;
        justify-content: space-between;
        max-width: $scale-max-width;
        margin-bottom: $spacer-7;
        @include lg {
            margin-bottom: 0;
            max-width: none;
        }
    }
    &__option-label {
        display: inline-flex;
        justify-content: center;
        @include lg {
            width: 7.25rem;
        }
    }
    &__option-input {
        width: 0;
        height: 0;
        position: absolute;
        opacity: 0;
    }
    &__option-input:checked ~ &__option-radio {
        border-color: $color-royal;
        background: $color-royal;
        color: $color-white;
    }
    &__option-input:focus ~ &__option-radio {
        outline: 2px solid $color-white;
        box-shadow: 0px 0px 0px 4px $color-focus;
        outline-offset: 0px;
    }
    &__option-radio {
        width: $spacer-8;
        height: $spacer-8;
        border-radius: 50%;
        border: 2px solid $color-grey-40;
        text-align: center;
        cursor: pointer;
        transition: $transition-default;
        &:hover {
            background: $color-grey-5;
        }
    }
    &__scale {
        max-width: $scale-max-width;
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacer-4;
        @include lg {
            display: none;
        }
    }
    &__scale-item {
        font-size: $font-size-x-small;
    }
    &__buttons {
        display: flex;
        justify-content: flex-end;
        button {
            width: auto;
        }
        @include lg {
            display: block;
        }
    }
}
