.metric {
    &__metric {
        * {
            font-weight: $font-weight-bold;
            color: $color-maroon;
            font-size: 3rem;
            line-height: 3.125rem;
            @include md {
                font-size: 3.5rem;
            }
        }
        @include nlc {
            margin-bottom: $spacer-4;
        }
    }
    @include nlc {
        margin-bottom: $spacer-4;
    }
}
