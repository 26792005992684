.accordion {
    border-radius: $border-radius-sm;
    background: $color-white;
    box-shadow: $elevation-closest;
    border: 1px solid $color-grey-5;
    &:not(:last-child) {
        margin-bottom: $spacer-12;
    }
    &__eyebrow {
        display: flex;
        flex-direction: column;
        * {
            font-size: $font-size-small;
            font-weight: $font-weight-regular;
            line-height: $line-height-small;
            color: $color-grey-80;
        }
    }
    &__trigger {
        cursor: pointer;
        border: none;
        padding: $spacer-6 $spacer-16 $spacer-6 $spacer-6;
        text-align: left;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        background: none;
        @include h4;
        &:not(:last-child) {
            margin-bottom: 0;
        }
    }
    &__item {
        position: relative;
        border-radius: $border-radius-sm;
        border-radius: 0;
        &:not(:first-child) {
            border-top: 1px solid $color-grey-20;
        }
    }
    &__content-wrapper {
        overflow: clip;
    }
    &__content {
        padding: 0 $spacer-6 $spacer-6 $spacer-6;
        @include md {
            padding: 0 4.5rem $spacer-6 $spacer-6;
        }
        *:not(a) {
            color: $color-grey-60 !important;
        }
    }

    &__icon {
        position: absolute;
        right: $spacer-6;
        top: 1.6875rem;
        cursor: pointer;
        border: 1px solid $color-grey-40;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-grey-60;
        transition: transform 0.2s;
        transform: rotate(0deg);
        width: $spacer-6;
        height: $spacer-6;
        font-size: 0.75rem;
        @include md {
            top: $spacer-6;
            width: $spacer-8;
            height: $spacer-8;
            font-size: 1rem;
        }
        &--close {
            color: $color-white;
            background: $color-grey-80;
            border: 1px solid transparent;
            transform: rotate(45deg);
        }
        &--eyebrow {
            top: $spacer-8;
        }
    }
    &--numbered & {
        &__trigger {
            padding: $spacer-16 $spacer-16 $spacer-6 $spacer-6;
            @include md {
                padding: $spacer-6 $spacer-16 $spacer-6 $spacer-20;
            }
        }
        &__content {
            padding-bottom: $spacer-6;
            @include md {
                padding-left: $spacer-20;
            }
        }
        &__count {
            position: absolute;
            top: 0;
            padding: $spacer-6 $spacer-6 0;
            color: $color-titanium;
            font-weight: $font-weight-bold;
            font-size: $font-size-mobile-h2;
            width: $spacer-20;
            @include md {
                padding: $spacer-6;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: $font-size-h2;
                height: 5rem;
            }
        }
        &__icon {
            top: 4.2rem;
            @include md {
                top: $spacer-6;
            }
        }
    }
}
