@use 'sass:math';

/* === Breakpoints === */

@mixin md {
    @media (min-width: map-get($breakpoints, 'md')) {
        @content;
    }
}

@mixin lg {
    @media (min-width: map-get($breakpoints, 'lg')) {
        @content;
    }
}
/* === Generate Responsive Utilities === */

@mixin responsive($list, $important: false) {
    @each $breakpoint, $size in $breakpoints {
        @if ($breakpoint == 'sm') {
            @each $property, $map in $list {
                $prefix: map-get($map, 'prefix');
                $values: map-get($map, 'values');
                @each $k, $v in $values {
                    .#{$prefix}-#{$k} {
                        @if ($important) {
                            #{$property}: $v !important;
                        } @else {
                            #{$property}: $v;
                        }
                    }
                }
            }
        }
        @if ($breakpoint != 'sm') {
            @media (min-width: $size) {
                @each $property, $map in $list {
                    $prefix: map-get($map, 'prefix');
                    $values: map-get($map, 'values');
                    @each $k, $v in $values {
                        .#{$prefix}-#{$breakpoint}-#{$k} {
                            @if ($important) {
                                #{$property}: $v !important;
                            } @else {
                                #{$property}: $v;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* === Layout === */

@mixin container {
    max-width: $max-width-container;
    margin: 0 auto;
    width: 100%;
    padding: 0 math.div($grid-gap, 2);
}

@mixin section {
    padding-top: $spacer-12;
    padding-bottom: $spacer-12;
    @include md {
        padding-top: $spacer-24;
        padding-bottom: $spacer-24;
    }
}

@mixin section-top {
    padding-top: $spacer-12;
    @include md {
        padding-top: $spacer-24;
    }
}

@mixin section-bottom {
    padding-bottom: $spacer-12;
    @include md {
        padding-bottom: $spacer-24;
    }
}

/* === Focus States === */

@mixin focus {
    &:focus-visible {
        outline: 2px solid $color-white;
        box-shadow: 0px 0px 0px 4px $color-focus;
        outline-offset: 0px;
    }
}

@mixin imgInsideLinkFocus {
    &:focus {
        outline: none;
        box-shadow: none;
    }
    &:focus * {
        outline: 2px solid $color-white;
        box-shadow: 0px 0px 0px 4px $color-focus;
    }
}

/* === Typography === */

@mixin body {
    font-size: $font-size-mobile-body;
    line-height: $line-height-mobile-body;
    color: $color-grey-80;
    &:not(:last-child) {
        margin-bottom: $spacer-4;
    }
    @include md {
        font-size: $font-size-body;
        line-height: $line-height-body;
    }
}

@mixin h1 {
    font-size: $font-size-mobile-h1;
    line-height: $line-height-mobile-h1;
    font-weight: $font-weight-regular;
    color: $color-black;
    &:not(:last-child) {
        margin-bottom: $spacer-6;
    }
    @include lg {
        font-size: $font-size-h1;
        line-height: $line-height-h1;
    }
}

@mixin h2 {
    font-size: $font-size-mobile-h2;
    line-height: $line-height-mobile-h2;
    font-weight: $font-weight-regular;
    color: $color-black;
    &:not(:last-child) {
        margin-bottom: $spacer-4;
    }
    @include md {
        font-size: $font-size-h2;
        line-height: $line-height-h2;
    }
}

@mixin h3 {
    font-size: $font-size-mobile-h3;
    line-height: $line-height-mobile-h3;
    font-weight: $font-weight-regular;
    color: $color-black;
    &:not(:last-child) {
        margin-bottom: $spacer-4;
    }
    @include md {
        font-size: $font-size-h3;
        line-height: $line-height-h3;
        font-weight: $font-weight-bold;
    }
}

@mixin h4 {
    font-size: $font-size-mobile-h4;
    line-height: $line-height-mobile-h4;
    color: $color-black;
    font-weight: $font-weight-bold;
    &:not(:last-child) {
        margin-bottom: $spacer-2;
    }
    @include md {
        font-size: $font-size-h4;
        line-height: $line-height-h4;
    }
}

@mixin h5 {
    font-size: $font-size-mobile-h5;
    line-height: $line-height-mobile-h5;
    color: $color-black;
    font-weight: $font-weight-bold;
    &:not(:last-child) {
        margin-bottom: $spacer-2;
    }
    @include md {
        font-size: $font-size-h5;
        line-height: $line-height-h5;
    }
}

@mixin lead {
    font-size: $font-size-mobile-lead;
    line-height: $line-height-mobile-lead;
    font-weight: $font-weight-regular;
    color: $color-grey-80;
    &:not(:last-child) {
        margin-bottom: $spacer-4;
    }
    @include md {
        font-size: $font-size-lead;
        line-height: $line-height-lead;
    }
    *:last-child {
        margin-bottom: 0;
    }
}

@mixin small {
    font-size: $font-size-mobile-small;
    line-height: $line-height-mobile-small;
    color: $color-grey-80;
    &:not(:last-child) {
        margin-bottom: $spacer-2;
    }
    @include md {
        font-size: $font-size-small;
        line-height: $line-height-small;
    }
}

@mixin x-small {
    font-size: $font-size-x-small;
    line-height: $line-height-x-small;
    color: $color-grey-80;
    &:not(:last-child) {
        margin-bottom: $spacer-4;
    }
}

@mixin list {
    font-size: $font-size-mobile-body;
    line-height: $line-height-mobile-body;
    @include md {
        font-size: $font-size-body;
        line-height: $line-height-body;
    }
}

/* === Elevation === */

@mixin elevation-closest {
    box-shadow: $elevation-closest !important;
}

@mixin elevation-close {
    box-shadow: $elevation-close !important;
}

@mixin elevation-far {
    box-shadow: $elevation-far !important;
}

@mixin elevation-farthest {
    box-shadow: $elevation-farthest !important;
}

@mixin lift {
    @include lg {
        transition: $transition-default;
        &:active {
            outline: none;
        }
        &:hover {
            transform: translateY(-$spacer-1);
            box-shadow: $elevation-closest;
        }
    }
}

/* === Gradients === */

@mixin gradient {
    background: linear-gradient(
            180deg,
            rgba(137, 141, 141, 0.2) 0%,
            rgba(137, 141, 141, 0) 50%
        ),
        $color-white;
}

/* === Line Clamp === */

@mixin line-clamp($x: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $x;
    line-clamp: $x;
    -webkit-box-orient: vertical;
}

/* Media */

@mixin aspect-ratio($width, $height) {
    width: 100% !important;
    padding-top: calc($height / $width * 100%) !important;
}

/* Pseudo Elements */

@mixin nlc {
    &:not(:last-child) {
        @content;
    }
}

/* Buttons */

@mixin resetButton {
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    color: inherit;
    font: inherit;
    cursor: pointer;
}

/* === Scrollbar === */

@mixin scrollbar {
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: auto;
    scrollbar-color: $color-grey-20 $color-white;
    &::-webkit-scrollbar-track {
        background: $color-white;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $color-grey-20;
        border-radius: 10px;
        border: 3px solid $color-white;
    }
    &::-webkit-scrollbar {
        width: 10px;
    }
}

/* === Navigation === */

@mixin desktop-navbar {
    @media (min-width: 1200px) {
        @content;
    }
}
