.hero {
    height: 22.5rem;
    display: flex;
    position: relative;
    overflow-y: hidden;
    background: $color-grey-5;
    @include md {
        height: 28.75rem;
    }
    &--lg {
        @include md {
            height: 33rem;
        }
        @include lg {
            .hero__foreground-image {
                padding-bottom: 52px !important;
            }
        }
    }
    &--hide-boxes & {
        &__boxes {
            position: absolute;
            left: -10000px;
            top: auto;
            width: 1px;
            height: 1px;
            overflow: hidden;
        }
    }
    &__container {
        @include lg {
            @include container;

            display: flex;
        }
        margin-left: auto;
        z-index: 1;
        flex: 1;
        position: relative;
    }
    &__boxes {
        @include lg {
            margin-top: 3.4375rem;
        }
    }
    &__large-box {
        background: rgba($color-crimson, 0.9);
        margin-left: 2.36rem;
        padding: $spacer-4;
        position: relative;
        z-index: 1;
        @include md {
            padding: $spacer-6 $spacer-4;
        }
        @include lg {
            $size: 21.875rem;
            width: $size;
            height: $size;
            padding: $spacer-6;
            margin-left: 6.875rem;
        }
    }
    &__small-box {
        width: $spacer-6;
        height: $spacer-6;
        background: $color-crimson;
        margin-left: 0.875rem;

        @include lg {
            margin-left: $spacer-20;
            width: 1.875rem;
            height: 1.875rem;
        }
    }
    &__foreground {
        height: 100%;
        width: 100%;
        position: absolute !important;
        top: 0;

        @include lg {
            position: relative !important;
            max-width: 34.375rem;
            margin-left: auto;
        }
    }
    &__foreground-image {
        @media (max-width: map-get($breakpoints, 'lg')) {
            padding-top: 6.875rem !important;
            object-position: center center !important;
        }
    }
    video.hero__foreground-image {
        height: 100%;
        object-fit: contain;
    }
    &__title * {
        margin-bottom: 0;
        color: $color-white;
        font-size: 1rem;
        line-height: 1.75rem;
        @include lg {
            font-size: 2.375rem;
            line-height: 3.125rem;
            margin-bottom: 0.75rem;
        }
    }
    &__description {
        color: $color-white;
        display: none;
        @include lg {
            display: block;
        }
        * {
            color: $color-white;
            font-size: $font-size-h4;
            line-height: 1.875rem;
        }
    }
    &__images {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: 0;
        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: right;
            z-index: 2;
        }
    }
    &__image {
        height: 100%;
        width: 100%;
    }
}
