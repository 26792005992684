.comparison {
   &__wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
   }
   &__button {
      width: $spacer-10;
      height: $spacer-10;
      border-radius: 50%;
      background: rgba($color-white, 90%);
      border: $spacer-1 $color-white solid;
      cursor: ew-resize;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: auto;
   }
   &__handle {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
   }
   &__line {
      background: $color-white;
      width: $spacer-1;
      height: calc(50% - 20px);
      pointer-events: auto;
      cursor: ew-resize;
   }
   &__arrows {
      transform: rotate(0.25turn);
      color: $color-crimson;
   }
}
