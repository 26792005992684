.footer {
    @include section;
    margin-top: auto;
    hr {
        border-color: rgba($color-white, 20%);
        margin: $spacer-7 0;
        @include md {
            margin: $spacer-12 0;
        }
    }
    a:hover {
        text-decoration: underline;
    }
    &__list {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
        margin-bottom: 0;
    }
    &__item {
        margin-bottom: $spacer-2;
    }
    &__link {
        text-decoration: none;
        color: $color-grey-20;
        @include list;
    }

    &__bottom {
        display: flex;
        flex-direction: column-reverse;
        gap: $spacer-6;
        @include lg {
            gap: 0;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
        }
    }
    &__bottom-content {
        @include md {
            display: flex;
            flex-direction: column;
        }
        @include lg {
            display: flex;
            flex-direction: row;
        }
    }
    &__bottom-text {
        font-size: $font-size-x-small;
        line-height: $line-height-x-small;
        margin-bottom: $spacer-6;
        @include lg {
            margin-bottom: 0;
            margin-right: $spacer-10;
        }
    }
    &__bottom-links {
        display: flex;
        flex-direction: column;
        @include md {
            margin-bottom: 0;
            display: flex;
            flex-direction: row;
        }
    }
    &__bottom-link {
        color: $color-grey-60;
        font-size: $font-size-x-small;
        line-height: $line-height-x-small;
        text-decoration: none;
        position: relative;
        background: none;
        border: none;
        text-align: left;
        @include focus;
        @include nlc {
            margin-bottom: $spacer-2;
        }
        &:hover {
            text-decoration: underline;
        }
        @include md {
            margin-bottom: 0;
            @include nlc {
                margin-right: $spacer-6;
                margin-bottom: 0;
            }
            &:not(:last-child):after {
                content: '|';
                color: $color-grey-40;
                position: relative;
                left: $spacer-2;
            }

            &:hover:after {
                text-decoration: underline;
                text-decoration-color: $color-slate;
            }
        }
        &--with-icon {
            display: flex;
            align-items: center;
            gap: $spacer-2;
        }
    }
    &--light {
        background: $color-grey-5;
        .footer__link {
            color: $color-grey-60;
        }
        .footer__bottom {
            color: $color-grey-60;
        }
        .footer__bottom-link,
        .footer__bottom-link * {
            color: $color-grey-60;
        }
    }
    &--dark {
        background: $color-slate;
        .social__text {
            color: $color-white;
        }
        .social__link {
            color: $color-white;

            &:hover {
                opacity: 0.8;
            }
        }
        .country-select__icon {
            color: $color-white;
        }
        .country-select__dropdown {
            .dropdown__trigger {
                color: $color-white;
            }
            .dropdown__arrow {
                width: 0.875rem;
                display: inline-flex;
            }
        }
        h5 {
            color: $color-white;
        }
        .footer__bottom {
            color: $color-grey-20;
        }
        .footer__bottom-link,
        .footer__bottom-link * {
            color: $color-grey-20;
        }
    }
}

.social {
    display: flex;
    flex-direction: column;
    @include md {
        align-items: center;
        flex-direction: row;
    }
    &__text {
        line-height: normal;
    }
    &__links {
        flex-shrink: 0;
        margin-top: $spacer-2;
        align-items: center;
        line-height: normal;
        @include md {
            margin-top: 0;
            margin-left: $spacer-6;
        }
    }
    &__link {
        color: $color-grey-60;
        display: inline-flex;
        line-height: normal;
        &:hover {
            opacity: 0.8;
        }
        &:not(:last-child) {
            margin-right: 1.5rem;
            @include md {
                margin-right: 0.75rem;
            }
        }
    }
    &__item {
        font-size: 1.5rem;
    }
}

.country {
    position: relative;
    @include md {
        margin-left: auto;
    }
    &__trigger {
        background: none;
        border: none;
        color: $color-white;
        font-size: $font-size-small;
        line-height: $spacer-5;
        display: flex;
        align-items: center;
        @include focus;
        &:hover {
            opacity: 0.8;
        }
    }
    &__icon {
        &--active svg {
            transform: rotateX(180deg);
        }
    }
    &__globe {
        font-size: $spacer-4;
    }
    &__title {
        margin: 0 $spacer-3;
    }
    &__dropdown {
        position: absolute;
        top: $spacer-8;
        z-index: 3;
        background: $color-white;
        min-width: 14rem;
        max-height: 36rem;

        overflow-y: scroll;
        @include md {
            right: 0;
            max-height: 25.8rem;
        }
    }
    &__dropdown-item {
        padding: $spacer-2 $spacer-4;
        font-size: $font-size-small;
        line-height: $line-height-small;
        display: block;
        color: $color-grey-80;
        text-decoration: none;
        transition: $transition-default;
        &:hover {
            background-color: $color-grey-5;
        }
    }
}
