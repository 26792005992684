.form {
    &__wrapper {
        // Offset when thank you message submitted. Needed for fixed headers.
        scroll-margin-top: var(--form-scroll-margin-top);
    }
    &__fields {
        display: grid;
        grid-gap: $spacer-6;
        margin-bottom: $spacer-8;
        grid-template-columns: 1fr;
        @include lg {
            grid-template-columns: repeat(6, 1fr);
        }
    }
    &__field {
        grid-column: span 3;
        img {
            max-width: 100%;
        }
        &--half-new-line {
            @include lg {
                grid-column: span 6;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: $grid-gap;
            }
        }
        &--full {
            @include lg {
                grid-column: span 6;
            }
        }
        &--third {
            @include lg {
                grid-column: span 2;
            }
        }
        &--small * {
            font-size: $font-size-small;
            line-height: $line-height-small;
        }
    }
    &__buttons {
        display: flex;
        justify-content: flex-end;
        button {
            width: auto;
        }
        @include lg {
            display: block;
        }
    }
    &__step {
        display: grid;
        grid-gap: $spacer-6;
        grid-column: span 3;
        @include lg {
            grid-column: span 6;
        }
    }
    &__step-page {
        display: flex;
        align-items: center;
        gap: $spacer-6;
        margin-bottom: $spacer-10;
        * {
            margin-bottom: 0 !important;
        }
    }
    &__step-counter {
        color: $color-white;
        background: rgba(53, 101, 132, 0.76);
        padding: $spacer-1 $spacer-3;
        border-radius: $border-radius-lg;
        display: inline-block;
    }
    &__result {
        padding: $spacer-1;
        display: flex;
        align-items: center;
        gap: $spacer-3;
        &--correct {
            background: rgba($color-ocean, 0.1);
        }
        &--incorrect {
            background: rgba($color-crimson, 0.1);
        }
    }
    &__result-radio {
        width: $spacer-6;
        height: $spacer-6;
        border-radius: 100%;
        background: white;
        border: 2px solid $color-slate;
        &--correct {
            background: $color-focus;
            border: 2px solid $color-focus;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after {
                content: '';
                position: absolute;
                width: $spacer-2;
                height: $spacer-2;
                background: white;
                border-radius: 100%;
            }
        }
    }
    &__group {
        margin-bottom: $spacer-6;
    }
    &__label {
        color: $color-black;
        &--multi {
            margin-bottom: $spacer-4;
            font-weight: bold;
        }
    }
    * &__input {
        display: block;
        padding: $input-padding;
        border: 1px solid $color-grey-60;
        border-radius: $input-border-radius;
        color: $color-black;
        font-size: $font-size-body;
        width: 100%;
        height: $input-height-md;
        @include focus;
        &::placeholder {
            color: $color-grey-40;
            font-size: $font-size-body;
        }
        &--icon {
            padding-left: 2.375rem !important;
        }
        &--search {
            padding-right: 2.25rem !important;
        }
        &--error {
            border: 1px solid $color-error;
        }
        &--textarea {
            min-height: $spacer-20;
        }
        &:disabled {
            cursor: not-allowed;
            color: $color-titanium;
            background: $color-grey-5;
            text-decoration: none;
            &:hover {
                color: $color-titanium;
                background: $color-grey-5;
            }
        }
    }
    &__item {
        display: block;
        width: fit-content;
        cursor: pointer;
        padding-left: 2.25rem;
        position: relative;
        color: $color-black;
        margin-bottom: $spacer-2;
        user-select: none;
        &:focus-within .form__check {
            outline: 2px solid $color-white;
            box-shadow: 0px 0px 0px 4px $color-focus;
            outline-offset: 0px;
        }
        &:hover .form__check {
            border: 2px solid $color-black;
        }
        &:hover .form__selector:checked ~ .form__check {
            background: $color-focus-hover;
            border: 2px solid $color-focus-hover;
        }
    }
    &__check {
        position: absolute;
        top: $spacer-1;
        left: 0;
        width: $spacer-6;
        height: $spacer-6;
        border-radius: 0.125rem;
        background: $color-white;
        border: 2px solid $color-slate;
        transition: $transition-default;
        &:after {
            content: '';
            display: none;
            left: 7px;
            position: absolute;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid $color-white;
            border-width: 0 3px 3px 0;
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        &--radio {
            border-radius: 50%;
            &:after {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                top: 50%;
                background: $color-white;
                transform: translate(-50%, -50%);
                left: 50%;
            }
        }
    }
    &__selector {
        opacity: 0;
        position: absolute;
        height: 0;
        width: 0;
        &:checked ~ .form__check {
            background: $color-focus;
            border: 2px solid $color-focus;
            &:after {
                display: block;
            }
        }
        &:disabled:checked ~ .form__check--radio {
            background: $color-grey-40;
            border-color: $color-slate;
        }
    }
    &__error {
        margin-top: $spacer-8;
        padding: $spacer-6;
        background: mix(white, $color-crimson, 85%);
    }
    &__validation {
        position: relative;
        &--error {
            border: 1px solid $color-error;
            border-radius: $spacer-1;
        }
    }
    &__icon {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: $spacer-4;
        color: $color-grey-40;
    }
    &__search {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: $spacer-4;
        color: $color-grey-40;
    }
    &__clear {
        @include resetButton;
        position: absolute;
        cursor: pointer;
        transform: translateY(-50%);
        top: 50%;
        right: $spacer-4;
        color: $color-grey-40;
        transition: $transition-default;
        line-height: normal;
        &:focus-visible {
            outline: none;
            color: $color-crimson;
        }
        &:hover {
            color: $color-crimson;
        }
    }
    &__helper {
        margin-top: $spacer-1;
        font-size: $font-size-x-small;
        line-height: $line-height-x-small;
        color: $color-grey-60;
    }
    &__suggestions {
        position: relative;
    }
    &__suggestions-list {
        z-index: 2;
        position: absolute;
        background: $color-white;
        list-style: none;
        width: 100%;
        @include elevation-far;
    }
    &__suggestions-item {
        padding: $spacer-4;
        cursor: pointer;
        &:hover,
        &--focused {
            background: $color-grey-5;
        }
    }
    &__quiz-results {
        &:not(:first-child) {
            margin-top: $spacer-6;
        }
        .content section.section:last-child {
            padding-bottom: 0;
        }
    }
    &__results {
        .content section.section {
            background: transparent;
        }
        .content:first-child section.section:first-child {
            padding-top: 0;
        }
        .content section.section:last-child {
            padding-bottom: 0;
        }
        .container {
            padding: 0;
        }
    }
    &--sm & {
        &__input {
            padding: $input-padding-sm;
            font-size: $font-size-small;
            height: $input-height-sm;
            &--textarea {
                min-height: $spacer-16;
            }
            &::placeholder {
                font-size: $font-size-small;
            }
        }
        .select__control {
            height: $input-height-sm !important;
        }
    }
    &--lg & {
        &__input {
            padding: $input-padding-lg;
            height: $input-height-lg;
            &--textarea {
                min-height: $spacer-24;
            }
        }
    }
}

/* === React Select ==== */

.select {
    &__control {
        border: 1px solid $color-grey-60 !important;
        border-radius: $input-border-radius !important;
        color: $color-grey-40 !important;
        min-height: $input-height-md !important;
        cursor: pointer !important;
        &--menu-is-open svg {
            transform: rotate(180deg);
        }
    }
    &__multi-value {
        background: $color-grey-5 !important;
    }
    &__multi-value__label {
        font-size: $font-size-x-small !important;
        color: $color-grey-80 !important;
    }
    &__multi-value__remove {
        color: $color-grey-40 !important;
        &:hover {
            background: transparent !important;
            color: $color-crimson !important;
        }
    }
    &__input {
        color: $color-black !important;
    }
    &__value-container {
        padding: 0 $spacer-4 !important;
    }
    &__input-container {
        margin: 0 !important;
        padding: 0 !important;
    }
    &__placeholder {
        margin: 0 !important;
        color: $color-grey-40 !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
    }
    &__single-value {
        font-size: $font-size-body;
        margin: 0 !important;
    }

    &__option {
        padding: 0 $spacer-4 !important;
        height: $input-height-md !important;
        display: flex !important;
        align-items: center !important;
        line-height: normal !important;
        cursor: pointer !important;
        &--is-focused {
            background: $color-grey-5 !important;
            color: $color-black !important;
        }
        &--is-selected {
            background: $color-sky-25 !important;
            color: $color-black !important;
        }
    }
    &__indicator {
        padding: 0 !important;
    }
    &__indicator-separator {
        display: none;
    }
    &__dropdown-indicator {
        padding-right: $spacer-3 !important;
    }
    &__dropdown-indicator svg {
        fill: $color-grey-80;
        color: $color-grey-80;
        width: $spacer-4;
        height: $spacer-4;
    }
    &__clear-indicator {
        padding-right: $spacer-2 !important;
    }
    &__menu {
        @include elevation-far;
        margin-top: $spacer-1 !important;
        margin-bottom: 0 !important;
        border-radius: 0 !important;
    }
    &__menu-list {
        padding: 0 !important;
    }
    &--error & {
        &__control {
            border: 1px solid $color-error !important;
        }
    }
}

.form--lg .select {
    &__control {
        min-height: $input-height-lg !important;
    }
    &__option {
        height: $input-height-lg !important;
    }
}

.form--sm .select {
    &__control {
        min-height: $input-height-sm !important;
    }
    &__single-value {
        font-size: $font-size-small;
    }
    &__placeholder {
        font-size: $font-size-small;
    }
    &__option {
        height: $input-height-sm !important;
    }
}

.form .select .select__control--is-focused {
    outline: 2px solid $color-white !important;
    box-shadow: 0px 0px 0px 4px $color-focus !important;
    outline-offset: 0px !important;
}

/* === Form Sidebar ==== */

.formside {
    background: $color-grey-60;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    * {
        color: $color-white;
    }
    &__inner {
        padding: $spacer-8;
        li::marker {
            color: $color-white !important;
        }
    }
    &__description {
        margin-bottom: $spacer-8;
        @include lg {
            margin-bottom: $spacer-10;
        }
    }
    &__contact {
        padding-bottom: 0 !important;
        @include md {
            display: flex;
        }
        @include lg {
            display: block;
        }
    }
    &__contact-item {
        &:not(:last-child) {
            margin-bottom: $spacer-6;
            @include lg {
                margin-bottom: $spacer-10;
            }
        }
        &:last-child {
            @include md {
                margin-left: $spacer-6;
            }
            @include lg {
                margin-left: 0;
            }
        }
        * {
            color: $color-white !important;
        }
    }
    &__image {
        height: 26.75rem;
        object-fit: cover;
        clip-path: polygon(0 11%, 101% 0%, 101% 100%, 0% 100%);
        display: none;
        position: relative;
        @include lg {
            display: block;
        }
    }
}

/* === Form Main ==== */

.formmain {
    @include lg {
        background: $color-white;
        box-shadow: $elevation-closest;
    }
}

.formcard {
    display: grid;
    grid-template-columns: 1fr;
    @include lg {
        grid-template-columns: 570px 1fr;
    }
    &--centered {
        grid-template-columns: 1fr;
    }
    &__media {
        display: block;
        height: 320px;
        order: 1;
        @include lg {
            order: 2;
            min-height: 420px;
            height: 100%;
        }
    }
    &__content {
        padding: $spacer-8;
        order: 2;
        @include lg {
            padding: $spacer-12;
            order: 1;
        }
        &--centered {
            max-width: 570px;
            margin: auto;
        }
    }
    &__description {
        margin-bottom: $spacer-8;
        @include lg {
            margin-bottom: $spacer-10;
        }
    }
}
