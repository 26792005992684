button {
    cursor: pointer;
}

.btn {
    border-radius: var(--btn-border-radius);
    background: none;
    border: none;
    padding: $btn-padding;
    font-size: $font-size-body;
    font-weight: var(--btn-font-weight);
    transition: $transition-default;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: normal;
    text-decoration: none;
    display: flex;
    width: 100%;
    @include md {
        display: inline-flex;
        width: auto;
    }
    cursor: pointer;
    @include focus;
    &:active {
        transform: scale(0.95);
    }
    &-xl {
        padding: $btn-padding-xl;
        font-size: $font-size-lead;
    }
    &-lg {
        padding: $btn-padding-lg;
        font-size: $font-size-h4;
    }
    &-sm {
        padding: $btn-padding-sm;
        font-size: $font-size-small;
    }
    &-primary {
        border: var(--btn-primary) 1px solid;
        background-color: var(--btn-primary);
        color: $color-white;
        &:hover {
            background-color: var(--btn-primary-hover);
        }
    }
    &-secondary {
        background-color: $color-slate;
        color: $color-white;
        &:hover {
            background-color: darken($color-slate, 10%);
        }
    }
    &-tertiary {
        border: var(--btn-tertiary) 1px solid;
        color: var(--btn-tertiary);
        background-color: $color-white;
        &:hover {
            border: var(--btn-tertiary-hover) 1px solid;
            color: var(--btn-tertiary-hover);
        }
    }
    &-accent {
        background-color: var(--btn-accent);
        color: $color-white;
        &:hover {
            background-color: var(--btn-accent-hover);
        }
    }
    &-ghost {
        color: var(--btn-primary);
        text-decoration: underline;
        &:hover {
            background: $color-grey-5;
            color: var(--btn-primary-hover);
        }
        &:active {
            background: $color-grey-5;
            font-size: $font-size-base;
            color: var(--btn-primary);
        }
    }
    &-muted {
        background-color: $color-grey-5;
        color: $color-grey-80;
        &:hover {
            background-color: darken($color-grey-5, 10%);
        }
    }
    &:disabled,
    &--disabled {
        cursor: not-allowed;
        color: $color-titanium;
        background: $color-grey-20;
        border: none;
        text-decoration: none;
        &:hover {
            border: none;
            color: $color-titanium;
            background: $color-grey-20;
        }
    }
    &--os-disabled {
        @media (max-width: map-get($breakpoints, 'lg')) {
            display: none;
        }
    }
    &--intrinsic {
        width: auto;
    }
    @each $key, $val in $social {
        &--#{$key} {
            color: $color-slate;
            border: none;
            background: $color-grey-5;
            @include lift;
            &:hover {
                color: $color-black;
                border: none;
            }
            svg {
                color: $val;
            }
        }
    }

    &-block {
        width: 100%;
    }
    &-group {
        align-items: center;
        @include md {
            display: inline-flex;
        }
        .btn {
            &:not(:last-child) {
                margin-bottom: $spacer-4;
                @include md {
                    margin-bottom: 0;
                    margin-right: $spacer-4;
                }
            }
        }
        &--stacked {
            flex-direction: column;
            align-items: stretch;
            .btn {
                @include nlc {
                    margin-right: 0;
                    margin-bottom: $spacer-4 !important;
                }
            }
        }
    }
}
