.person {
   display: flex;
   background: none;
   border: none;
   @include focus;
   &:hover &,
   &:focus & {
      &__link {
         text-decoration: none;
      }
      &__arrow {
         animation: linkarrow 0.8s infinite;
         position: relative;
      }
   }
   &__image {
      border-radius: 50%;
      width: 7.5rem;
      height: 7.5rem;
      flex-shrink: 0;
   }
   &__fullname {
      line-height: normal;
      margin-bottom: 0;
   }
   &__occupation p {
      @include small;
      color: $color-slate;
   }
   &__description {
      margin-top: $spacer-3;
      text-align: left;
      &--shorten {
         @include line-clamp(2);
         p {
            display: inline;
         }
         p:not(:first-child) {
            display: none;
         }
      }
   }
   &__link {
      color: $color-crimson;
      font-size: $font-size-body;
      line-height: $line-height-body;
      text-decoration: underline;
      text-align: left;
      margin-top: $spacer-3;
   }
   &__arrow {
      color: $color-crimson;
      margin-left: $spacer-2;
   }
   &__modal {
      padding: $spacer-6;
      background: $color-white;
      @include md {
         padding: $spacer-12;
      }
   }
   &__modal-bio {
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: normal;
   }
   &--modal {
      flex-direction: column;
      align-items: center;
      padding: $spacer-6;
      background: $color-white;
      @include md {
         padding: $spacer-12;
      }
      .person__fullname {
         margin-top: $spacer-4;
      }
      .person__occupation {
         text-align: center;
      }
      .person__description {
         margin-top: $spacer-10;
      }
   }
   &--1 {
      flex-direction: column;
      align-items: center;
      align-self: flex-start;
      .person__fullname {
         margin-top: $spacer-4;
         margin-bottom: 0;
         text-align: center;
      }
      .person__occupation {
         margin-top: $spacer-2;
         text-align: center;
      }
   }
   &--2 {
      flex-direction: column;
      align-items: center;
      align-self: flex-start;
      @include md {
         flex-direction: row;
         align-items: flex-start;
      }
      .person__image {
         @include md {
            width: $spacer-20;
            height: $spacer-20;
            margin-right: $spacer-6;
         }
      }
      .person__fullname {
         margin-top: $spacer-4;
         text-align: center;
         @include md {
            margin-top: 0;
            text-align: left;
         }
      }
      .person__occupation {
         margin-top: $spacer-2;
         text-align: center;
         @include md {
            margin-top: $spacer-1;
            text-align: left;
         }
      }
      .person__description {
         margin-top: $spacer-2;
         @include md {
            p {
               font-size: $font-size-small;
               line-height: $line-height-mobile-small;
            }
         }
      }
      .person__link {
         margin-top: $spacer-3;
         @include md {
            margin-top: $spacer-2;
            * {
               font-size: $font-size-small;
            }
         }
      }
   }
   &--3 {
      flex-direction: column;
      align-items: center;
      background: $color-white;
      padding: $spacer-6;
      border-radius: $border-radius-lg;
      @include lift;
      .person__fullname {
         margin-top: $spacer-4;
      }
      .person__occupation {
         margin-top: $spacer-2;
      }
      .person__description {
         display: none;
      }
      .person__link {
         display: none;
      }
   }
   &--4 {
      padding: $spacer-6;
      border-radius: $border-radius-lg;
      background: $color-white;
      width: 100%;
      height: 100%;
      align-items: center;
      flex-direction: column;
      @include md {
         flex-direction: row;
      }
      @include lift;
      .person__image {
         @include md {
            width: $spacer-24;
            height: $spacer-24;
            margin-right: $spacer-6;
         }
      }
      .person__fullname {
         text-align: center;
         margin-top: $spacer-4;
         @include md {
            text-align: left;
            margin-top: 0;
         }
      }
      .person__occupation {
         margin-top: $spacer-2;
         text-align: center;
         @include md {
            text-align: left;
         }
      }
      .person__description {
         display: none;
      }
      .person__link {
         display: none;
      }
   }
}
