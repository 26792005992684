.info {
    &__description {
        &:not(:last-child) {
            margin-bottom: $spacer-5;
            @include md {
                margin-bottom: $spacer-8;
            }
        }
    }
    &__buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &:not(:last-child) {
            margin-bottom: $spacer-12;
        }
    }
    &__button {
        &:not(:last-child) {
            margin-bottom: $spacer-4;
        }
    }
    &__media {
        &:not(:last-child) {
            margin-bottom: $spacer-8;
        }
    }
    &__wrapper {
        position: relative;
    }
    &--vertical {
        @include md {
            align-items: center;
        }
    }
}
