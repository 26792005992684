.richtext {
    &__link {
        display: inline;
        margin-bottom: 0;

        .link {
            display: inline-block;
            margin-bottom: 0;

            &__button {
                font-size: inherit;
                text-decoration: underline;
                margin-bottom: 0;
            }

            &__icon {
                display: none;
            }
        }
    }

    &__table {
        max-width: 100%;
        overflow-x: auto;
    }

    &__image {
        text-align: center;
    }

    &__error {
        h1,
        h2,
        h3,
        h4,
        h5,
        p {
            color: $color-crimson;
        }
    }

    &__press-release {
        table {
            border-spacing: 0;
            border-collapse: collapse;
            width: 100%;
        }
    }

    a,
    button {
        @include md {
            word-break: unset;
        }
    }

    ul {
        padding: 0 0 0 $spacer-6;

        li {
            margin: 0 0 0 $spacer-3;

            &::marker {
                color: var(--list-bullet-color);
                font-size: 115%;
            }
        }
    }

    img {
        max-width: 100%;
    }

    // Used for setting larger font size for specific sites
    &.info__description p:not([class]),
    &.info__description li:not([class]),
    &.card__description p:not([class]),
    &.card__description p:not([class]),
    &.feature__description p:not([class]),
    &.feature__description li:not([class]),
    &.resource__description p:not([class]),
    &.formside__description p:not([class]),
    &.person__description p:not([class]),
    &.statistic__description p:not([class]),
    &:not([class*=' ']) p:not([class]),
    &:not([class*=' ']) li:not([class]),
    .paragraph {
        line-height: var(--line-height-mobile-body);
        font-size: var(--font-size-mobile-body);

        @include md {
            line-height: var(--line-height-body);
            font-size: var(--font-size-body);
        }
    }
}
