@use 'sass:math';

.modal {
    width: 100%;
    height: 100%;
    background: rgba($color-black, 0.5);
    backdrop-filter: blur($spacer-1);
    position: fixed;
    z-index: 11;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &__content {
        max-width: map-get($breakpoints, 'lg');
        width: 100%;
        display: flex;
        flex-direction: column;
        top: $spacer-10;
        margin: $spacer-4;
        position: relative;
        cursor: default;

        &--disable-x {
            top: 0;
        }

        &--multi {
            max-width: 50rem;
        }
    }

    &__close {
        cursor: pointer;
        align-self: flex-end;
        margin-bottom: $spacer-1;
        background: var(--btn-primary);
        width: $spacer-12;
        height: $spacer-12;
        position: absolute;
        transform: translateY(-$spacer-14);
        top: 0;
        border: none;
        transition: 0.2s background;

        &:hover {
            background: var(--btn-primary-hover);
        }

        &:active {
            transform: translateY(-$spacer-14);
        }

        svg {
            fill: $color-white;
            width: math.div($spacer-10, 2);
            height: $spacer-8;
        }

        &--disable-x {
            opacity: 0;
        }
    }

    &__body {
        max-height: 85vh;
        overflow-y: auto;

        &--video {
            overflow: hidden;
        }
    }

    &__footer {
        display: flex;
        justify-content: right;
        column-gap: $spacer-2;
        background-color: $color-white;
        padding: 0 $spacer-9 $spacer-9 $spacer-9;
    }

    &__bodyModal {
        max-height: 80vh;
        text-align: left;
    }

    &__button {
        cursor: pointer;
        width: $spacer-24;
        align-items: center;
        height: $spacer-8;
    }

    &__video {
        position: relative;
        display: inline-flex;
        border: none;
        width: 100%;
        align-items: flex-end;
        cursor: pointer;
        @include focus;
    }

    &__pdf {
        position: relative;
        display: inline-flex;
        border: none;
        width: 100%;
        height: 70vh;
        align-items: flex-end;
        cursor: pointer;
        @include focus;
    }

    &__icon {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        z-index: 2;
        color: $color-white;
        font-size: $spacer-16;
        box-shadow: $elevation-closest;
        border-radius: 50%;

        .fa-primary {
            color: $color-slate;
            fill: $color-slate;
        }

        .fa-secondary {
            color: $color-white;
            fill: $color-white;
            opacity: 1;
        }
    }
}

.popup {
    background: $color-white;
    padding: $spacer-9;
    display: flex;
    flex-direction: column;

    &__head {
        display: flex;
        align-items: center;
        margin-bottom: $spacer-6;
    }

    &__title * {
        font-size: $font-size-h3;
        font-weight: bold;

        @include md {
            font-size: $font-size-h3;
        }
    }

    &__icon-wrapper {
        line-height: normal;
    }

    &__icon {
        margin-right: $spacer-3;
        color: $color-warning;
        font-size: $spacer-6;

        @include md {
            margin-right: $spacer-2;
        }
    }

    &__description {
        margin-bottom: $spacer-9;

        & p:not([class]),
        & li:not([class]) {
            line-height: var(--line-height-mobile-body);
            font-size: var(--font-size-mobile-body);

            @include md {
                line-height: var(--line-height-body);
                font-size: var(--font-size-body);
            }
        }
    }

    &__links {
        justify-content: flex-end;
        display: flex;
    }

    &__link {
        @include nlc {
            margin-right: $spacer-2;
        }
    }

    &__hr {
        margin-top: $spacer-8;
        margin-bottom: $spacer-8;
        border: 0;
        border-top: 1px solid $color-grey-20;
    }

    &--basic & {
        &__icon {
            display: none;
        }
    }

    &--multi & {
        &__links {
            flex-direction: column;
            gap: $spacer-6;
        }

        &__link {
            padding: $spacer-5 $spacer-6;
            border: 1px solid $color-grey-20;
            border-radius: $border-radius-lg;
            text-decoration: none;
            margin-right: 0;
            transition: $transition-default;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: $spacer-4;

            &:hover {
                background: $color-grey-5;
            }
        }

        &__icon {
            color: var(--btn-primary);
        }
    }
}
