.card {
	border-radius: $border-radius-lg;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	text-decoration: none;
	color: transparent;
	@include lift;
	@include focus;

	&__title {
		@include nlc {
			margin-bottom: $spacer-2;
		}
	}
	&__description {
		color: $color-grey-80;
		&:last-child {
			margin-bottom: $spacer-2;
		}
	}
	&__footnotes {
		margin-top: $spacer-4;
		margin-bottom: $spacer-2;
	}
	&__body {
		padding: $spacer-6;
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	&__body-content {
		justify-self: center;
	}
	&__arrow {
		color: $color-grey-40;
		font-size: 1.625rem;
		transition: $transition-default;
	}
	&__arrow-wrapper {
		margin-top: auto;
		padding-top: $spacer-2;
		display: flex;
		justify-content: flex-end;
	}
	&:hover & {
		&__arrow {
			color: $color-black;
		}
	}
	&--1 {
		.card__body {
			position: relative;
		}
	}

	&--2 {
		.card__title {
			margin-bottom: 0;
		}
		.card__body {
			position: relative;
			text-align: center;
		}
		.card__description {
			display: none;
			margin-bottom: 0;
			p {
				margin-bottom: 0;
			}
		}
		.card__arrow {
			display: none;
		}
		.card__footnotes {
			display: none;
			margin-bottom: 0;
			&--hoverable {
				display: block;
				@include lg {
					margin-top: $spacer-2;
					* {
						color: white;
						font-size: $font-size-x-small;
					}
				}
			}
		}

		@include lg {
			.card__description {
				display: block;
				opacity: 0;
				position: absolute;
				bottom: -3rem;
				left: 0;
				padding: $spacer-6;
				transition: 0.5s all;
				width: 100%;
				text-align: left;
			}

			&:hover .card__description {
				opacity: 1;
				background: rgba($color-black, 0.8);
				color: $color-white;
				bottom: 0rem;
				transition: 0.5s all;
				* {
					color: $color-white;
				}
			}
		}
	}
	&--3 {
		@include md {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			gap: $spacer-6;
			.card__image {
				grid-column: 1/6;
			}
			.card__body {
				grid-column: 6/-1;
				padding: $spacer-6 $spacer-6 $spacer-6 0;
			}
		}
	}
	&--3c {
		.card__title,
		.card__title * {
			margin-bottom: 0;
			text-align: center;
			@include md {
				font-size: $font-size-body;
				line-height: $line-height-body;
			}
		}
		.card__body {
			position: relative;
			text-align: center;
		}
		.card__description {
			display: none;
			margin-bottom: 0;
			p {
				margin-bottom: 0;
			}
		}
		.card__arrow {
			display: none;
		}
		.card__footnotes {
			display: none;
			margin-bottom: 0;
			&--hoverable {
				display: block;
				@include lg {
					margin-top: $spacer-2;
					* {
						color: white;
						font-size: $font-size-x-small;
					}
				}
			}
		}

		@include lg {
			.card__description {
				display: block;
				opacity: 0;
				position: absolute;
				bottom: -3rem;
				left: 0;
				padding: $spacer-6;
				transition: 0.5s all;
				width: 100%;
				text-align: left;
			}

			&:hover .card__description {
				opacity: 1;
				background: rgba($color-black, 0.8);
				color: $color-white;
				bottom: 0rem;
				transition: 0.5s all;
				* {
					color: $color-white;
				}
			}
		}
	}
	&--4 {
		@include md {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			gap: $spacer-6;
			.card__body {
				grid-column: 1/8;
				grid-row: 1;
				padding: $spacer-6 0 $spacer-6 $spacer-6;
			}
			.card__image {
				grid-row: 1;
				grid-column: 8/-1;
				margin-top: auto;
			}
		}
	}
}
