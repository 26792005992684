@import 'swiper/scss';
@import 'swiper/scss/pagination';
@import 'swiper/scss/effect-fade';
@import 'swiper/scss/zoom';

.swiper-pagination-progressbar {
   background: $color-grey-5;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
   background: $color-crimson;
}

div[class^='swiper'] {
   line-height: 0;
}
