.table {
    overflow: auto;
    @include elevation-closest;
    table {
        width: 100%;
        background: $color-white;
        border-spacing: 0;
        border-collapse: collapse;
        border: 1px solid $table-border-color;
    }
    tr {
        td {
            font-size: $font-size-small;
            line-height: $line-height-small;
            color: $color-grey-60;
            padding: $spacer-2 $spacer-4;
            min-width: 9.375rem;
            word-break: auto-phrase;
            * {
                padding: 0;
            }
            p {
                font-size: $font-size-small;
                line-height: $line-height-small;
                color: $color-grey-60;
            }
            figure {
                padding: $spacer-2 0;
                .richtext__image {
                    text-align: left;
                }
                * {
                    padding: 0;
                }
            }
        }
        &:first-child {
            td {
                font-size: $font-size-body;
                color: $color-black;
                font-weight: $font-weight-bold;
                padding: $spacer-4;
                background: $table-heading-color;
            }
        }
        &:not(:last-child) {
            border-bottom: 1px solid $table-border-color;
        }
        &:nth-child(2n + 3) {
            background: $table-zebra-color;
        }
    }
    &--disable-header {
        tr:first-child td {
            font-weight: $font-weight-regular;
            color: $color-grey-60;
            font-size: $font-size-small;
            background: $table-zebra-color;
            padding: $spacer-2 $spacer-4;
        }
        tr:nth-child(odd) {
            background: $table-zebra-color;
        }
    }
    &--disable-multiline-header {
        tr:first-child td {
            white-space: nowrap;
        }
    }
    &--header-align-center {
        tr:first-child td {
            text-align: center;
        }
    }
    &--header-align-right {
        tr:first-child td {
            text-align: right;
        }
    }
}
