.link {
	display: inline-flex;
	align-items: center;
	transition: $transition-default;
	color: $color-crimson;
	@include nlc {
		margin-bottom: $spacer-4;
	}
	&--lg {
		font-size: $font-size-h4;
	}
	&--sm {
		font-size: $font-size-x-small;
	}
	&__icon {
		color: var(--btn-primary);
		margin-left: $spacer-1;
	}
	&:hover &__icon {
		animation: linkarrow 0.8s infinite;
		position: relative;
	}
	&__button {
		color: $color-crimson;
		background: none;
		border: none;
		font-size: $font-size-body;
		line-height: normal;
		text-align: unset;
		text-decoration: underline;
		@include focus;
		&:hover {
			text-decoration: none;
		}
	}
}
