.toggle {
	&--active {
		cursor: default;
		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
	&--inactive & {
		&__media {
			@include lift;
		}
	}
}
