.contact {
    &:not(:last-child) {
        padding-bottom: $spacer-12;
    }
    &:not(:last-child) {
        border-bottom: 1px solid $color-titanium;
        margin-bottom: $spacer-12;
    }
    &__profile {
        border-radius: 50%;
        width: 177px;
        height: 177px;
        position: relative;
        margin: 0 auto;
    }
    &__icon-wrapper {
        width: $spacer-7;
        height: 2.4375rem;
        margin-right: $spacer-3;
        padding: $spacer-2 0;
    }
    &__icon {
        color: $color-black;
        font-size: 1.625rem;
        @include md {
            font-size: 2.5rem;
            min-height: 2.5rem;
            width: auto;
            margin-right: 0;
            margin-bottom: $spacer-4;
        }
    }
    &__item {
        display: flex;
        align-items: center;
        @include md {
            display: block;
        }
    }
    &__item-body {
        line-height: normal;
    }

    &__description p {
        color: $color-grey-80;
        font-size: $font-size-mobile-small;
        line-height: $line-height-mobile-small;
        @include md {
            font-size: $font-size-x-small;
            line-height: $line-height-x-small;
        }
    }
    &__email {
        color: $color-crimson;
        font-size: $font-size-small;
        line-height: $line-height-small;
        font-weight: $font-weight-bold;
        word-break: break-all;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
        * {
            font-size: $font-size-small;
            line-height: $line-height-small;
        }
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__email-domain {
        display: inline-block;
        text-decoration: underline;
    }
    &__address {
        color: $color-black;
        margin-top: $spacer-1;
        font-size: $font-size-small;
        line-height: $line-height-small;
        * {
            color: $color-black;
            font-size: $font-size-small;
            line-height: $line-height-small;
        }
    }
    &__phone {
        font-size: $font-size-small;
        line-height: $line-height-small;
        color: $color-black;
        * {
            color: $color-black;
            font-size: $font-size-small;
            line-height: $line-height-small;
        }
    }
}
