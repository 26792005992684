.lookup {

  &__error {
    margin-top: $spacer-8;
		padding: $spacer-6;
		background: mix(white, $color-crimson, 85%);
  }
  &__field-group {
    margin-bottom: $spacer-8;
  }
  &__label {
    margin-top: $spacer-8;
  }
  &__input {
    display: flex;
    align-items: baseline;
    margin-top: $spacer-8;
    .form__input {
      width: 18.75rem;
    }
    button {
      margin-left: $spacer-4;
    }
  }
  &__dropdown {
    max-width: 100%;
    @include md {
      max-width: 18.75rem;
    }
    position: relative;
  }
}
