#consent_blackbar {
    position: fixed;
    bottom: 0;
    z-index: 3 !important;
    width: 100%;
    margin: auto;
    max-width: 90rem !important;
    left: 0;
    right: 0;
    font-family: $font-family-base !important;
}

#consent_blackbar * {
    font-family: $font-family-base !important;
}

#truste-consent-track {
    border: none !important;
}

#consent_blackbar a {
    color: $color-white !important;
    text-decoration: underline !important;
}

#truste-consent-buttons {
    display: flex !important;
    gap: 0.625rem;
}

#truste-consent-buttons > #truste-consent-button,
#truste-consent-buttons > #truste-consent-required,
#truste-consent-buttons > #truste-show-consent {
    color: $color-white !important;
    padding: 0.625rem 1.5rem !important;
    line-height: $line-height-small !important;
    border-width: 0px !important;
    font-size: $font-size-base !important;
    font-family: "Merriweather Sans", Arial, sans-serif !important;
    border-radius: $input-border-radius !important;
}

#truste-consent-buttons > #truste-consent-button {
    background-color: $color-crimson !important;
}

#truste-consent-buttons > #truste-consent-button:hover {
    background-color: $color-maroon !important;
}

#truste-consent-buttons > #truste-consent-required,
#truste-consent-buttons > #truste-show-consent {
    background-color: $color-slate !important;
}

#truste-consent-buttons > #truste-consent-required:hover,
#truste-consent-buttons > #truste-show-consent:hover,
#truste-consent-buttons > #truste-consent-required:active,
#truste-consent-buttons > #truste-show-consent:active {
    background-color: $color-black !important;
}

#truste-consent-buttons > #truste-consent-button:active,
#truste-consent-buttons > #truste-consent-required:active,
#truste-consent-buttons > #truste-show-consent:active {
    padding: 0.5625rem 1.4375rem !important;
}

#truste-consent-buttons > #truste-consent-button:focus,
#truste-consent-buttons > #truste-consent-required:focus,
#truste-consent-buttons > #truste-show-consent:focus {
    padding: 0.625rem 1.5rem !important;
    box-shadow: 0px 0px 0px 1px $color-white, 0px 0px 0px 3px $color-focus;
}

#truste-consent-text {
    font-size: $font-size-small !important;
    font-weight: $font-weight-regular !important;
    line-height: $line-height-small !important;
}

#truste-consent-track {
    background-color: $color-grey-40 !important;
}

.truste-messageColumn {
    margin: 10px 0px 10px 0px !important;
}

#truste-consent-track {
    padding: 15px 60px;
}

@media (max-width: 1024px) {
    #truste-consent-track {
        padding: 15px 15px !important;
    }

    .truste-buttonsColumn {
        float: left !important;
    }
}
