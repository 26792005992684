.sitemap {
  &__list {
    @include md {
      &--columns-1 {
        column-count: 1;
      }
      &--columns-2 {
        column-count: 2;
      }
      &--columns-3 {
        column-count: 3;
      }
      &--columns-4 {
        column-count: 4;
      }
    }
  }
  &__category {
    list-style-type: none;
    break-inside: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: $spacer-4;
  }
  &__category-title {
    font-size: $font-size-h3;
    line-height: $line-height-h3;
    margin-bottom: $spacer-4;
  }
}