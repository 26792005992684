.tabs {
	&__menu {
		display: flex;
	}

	&__tab {
		padding: $spacer-6;
		border: none;
		cursor: pointer;
		position: relative;
		background: none;
		transition: $transition-default;
		box-shadow: 0px -4px 0px $color-grey-20 inset;

		&:hover {
			background: darken($color-grey-5, 3%);
		}

		&--grow {
			flex: 1;
		}

		&--icon-top {
			.tabs__icon {
				margin-bottom: $spacer-4;
				font-size: $spacer-10;
			}
		}

		&--icon-left {
			display: flex;
			align-items: center;
			justify-content: center;

			.tabs__icon {
				margin-right: $spacer-4;
			}
		}
	}

	&__glider {
		position: absolute;
		bottom: 0;
		width: 100%;
		left: 0;
		height: $spacer-1;
		background: var(--tab-glider-color);
		z-index: 1;
		transition: $transition-default;

		@for $i from 1 through 6 {
			$val: (
				$i - 1) * 100%;

			&--#{$i} {
				transform: translateX($val
				);
		}
	}
}

&__content {
	section {
		background: transparent !important;
		padding: $spacer-12 0 $spacer-12;

		.container {
			padding: 0;
		}

		&:last-child {
			padding-bottom: 0;
		}
	}
}

&__icon {
	font-size: $spacer-8;
}

&__title {
	font-size: $font-size-body;
	font-weight: $font-weight-bold;
	color: $color-black;
	word-break: break-word;
}
}