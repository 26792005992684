* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-margin-top: var(--scroll-margin-top-mobile);
    @include md {
        scroll-margin-top: var(--scroll-margin-top);
    }
}

hr {
    margin: $spacer-12 0;
}

.body {
    &--modal-active {
        overflow-y: hidden !important;
    }
}

html,
body,
body > div:first-child,
div#__next,
div#__next > div {
    height: 100%;
}
