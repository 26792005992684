.features {
   &__link {
      @include nlc {
         margin-bottom: $spacer-14;
      }
   }
   &__media {
      @include nlc {
         margin-bottom: $spacer-14;
      }
   }
   &__items {
      @include nlc {
         margin-bottom: $spacer-14;
      }
   }
}

.feature {
   &__title {
      * {
         @include h4;
      }
      @include nlc {
         margin-bottom: $spacer-2;
      }
   }
   &__description {
      @include nlc {
         margin-bottom: $spacer-4;
      }
   }
   &--center {
      text-align: center;
      display: grid;
      align-content: start;
   }
   &--elevated {
      box-shadow: $elevation-closest;
      padding: $spacer-6;
      background: $color-white;
   }
   &--offset-media {
      position: relative;
      padding-top: 67px;
      &.feature--center {
         .feature__media {
            justify-self: center;
         }
         .feature__media-item {
            object-position: center bottom !important;
         }
      }
      .feature__media {
         position: absolute;
         top: -3.125rem;
      }
      .feature__icon svg {
         height: 6.25rem;
      }
      .feature__media-wrapper {
         width: 8.125rem;
         height: 6.25rem;
      }
      .feature__media-item {
         object-position: left bottom !important;
      }
   }

   &--1 & {
      &__media {
         @include nlc {
            margin-bottom: $spacer-6;
         }
      }
   }
   &--2 {
      @include md {
         display: grid;
         grid-auto-flow: column;
         column-gap: $spacer-7;
      }
      .feature__media {
         margin-bottom: $spacer-6;
         @include md {
            margin-bottom: 0;
         }
         &--native {
            display: flex;
            @include md {
               height: 100%;
               align-items: center;
               justify-content: center;
            }
         }
      }
      .feature__icon {
         width: 100%;
         height: 100%;
         @include md {
            display: flex;
            align-items: center;
            justify-content: center;
         }
      }
   }
   &--3 {
      @include md {
         display: grid;
         grid-template-columns: repeat(12, 1fr);
         column-gap: $spacer-7;
      }
      .feature__media {
         grid-column: span 3;
         margin-bottom: $spacer-6;
         @include md {
            margin-bottom: 0;
         }
         &--native {
            display: flex;
            @include md {
               height: 100%;
               align-items: center;
               justify-content: center;
            }
         }
         &--secondary {
            grid-column: 10 / -1;
            margin-top: $spacer-4;
            margin-bottom: 0;
            @include md {
               margin-top: 0;
            }
         }
      }
      .feature__content {
         grid-column: span 6;
      }
      .feature__icon {
         width: 100%;
         height: 100%;
         @include md {
            display: flex;
            align-items: center;
            justify-content: center;
         }
      }
   }
}
