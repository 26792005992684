.async-select-xmark {
    margin-right: 0.438rem;
}

.async-select-container {
    width: 30rem;
    z-index: 2;
    position: absolute !important;
    right: 0;
    transform: translateY(-78%);
}

.async-select-mobile-container {
    display: block;
    position: relative !important;
    background-color: $color-white;
}

.async-select {
    &__option {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: flex;
        align-items: flex-end;
        background-color: unset !important;
        color: $color-grey-80 !important;

        &:first-of-type {
            display: none;
        }

        &:active {
            background-color: unset !important;
        }

        &:hover {
            background: $color-grey-5 !important;
        }

        &--is-focused {
            background: $color-grey-5 !important;
        }
    }

    &__indicator-separator {
        display: none !important;
    }

    &__control {
        border-radius: $input-border-radius !important;
        border-color: unset !important;
        box-shadow: unset !important;
        min-height: inherit !important;
        border-style: unset !important;
        height: 2rem;
        &:hover {
            border-color: unset !important;
        }
    }

    &__xmark {
        margin-right: 0.625rem;
        color: $color-grey-40;
        &:hover {
            color: $color-grey-80;
        }
    }

    &__magnifying_glass {
        color: $color-grey-40;
        &:hover {
            color: $color-grey-80;
        }
    }

    &__menu-notice {
        display: none !important;
    }

    &__input {
        min-width: 26.5rem !important;
    }
    &__placeholder {
        color: $color-grey-40 !important;
    }
}

.async-select-mobile {
    &__menu {
        width: 100% !important;
        margin-top: $spacer-2 !important;
    }

    &__option {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: flex;
        align-items: flex-end;
        background-color: unset !important;
        color: $color-grey-80 !important;
        z-index: 1;

        &:first-of-type {
            display: none;
        }

        &:active {
            background-color: unset !important;
        }

        &:hover {
            background: $color-grey-5 !important;
        }

        &--is-focused {
            background: $color-grey-5 !important;
        }
    }

    &__indicator-separator {
        display: none !important;
    }

    &__control {
        border-radius: $input-border-radius !important;
        border-color: $color-grey-40 !important;
        box-shadow: unset !important;
        min-height: inherit !important;

        &:hover {
            border-color: $color-grey-40 !important;
        }
    }

    &__xmark {
        margin-right: 0.438rem;
        color: $color-grey-40;
    }

    &__magnifying_glass {
        color: $color-grey-40;
        margin-right: 0.438rem;
    }

    &__clear-indicator {
        display: none !important;
    }

    &__menu-notice {
        display: none !important;
    }

    &__search_anchor {
        cursor: pointer !important;
    }
    
    &__input-container {
        cursor: text;
    }
}
