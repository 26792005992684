@keyframes linkarrow {
	from {
		left: 0rem;
	}
	50% {
		left: 0.5rem;
	}
	95% {
		left: -0.2rem;
	}
	to {
		left: 0rem;
	}
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
